import React, { useEffect, useState } from "react";
import { TableRow } from "@material-ui/core";
import {
  Dialog,
  Grid,
  Typography,
  IconButton,
  Divider,
  Button,
  Table,
  TableBody,
} from "@mui/material";
import { useStyles } from "../../../../Styles/fiStyles";
import CloseIcon from "@material-ui/icons/Close";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import * as actionSnackBar from "../../../../store/snackbar/action";
import NumberFormat from "react-number-format";
import * as rfqSlice from "../../../../store/rfq/rfqSlice";
import "./rfqBookModal.css";
import SuccessFailAnimation from "../../../fi/reusableComponents/SuccessFailAnimation";
import etg_logo from "../../../../assets/etg_logo.png";
import { bookToETG } from "../../../../services/etgBookingService.mjs";

const RfqBookModal = (props) => {
  const dispatch = useDispatch();
  const rfqList = useSelector((state) => state.rfqSlice.rfq);
  const { splitManner, postTradeSplitArrValues } = useSelector(
    (state) => state.rfqPostTradeSlice,
  );
  let rfqBookingModeUpdatingRows = useSelector(
    (state) => state.rfqSlice.rfqBookingModeUpdatingRows,
  );
  const classes = useStyles();
  const { open, type, handleClose } = props;
  const [xsValue, setXsValue] = useState(5);
  const token = sessionStorage.getItem("token");
  const data = props.data[0]?.data;
  const [etgBtn, setEtgBtn] = useState(false);
  const [startETGSending, setStartETGSending] = useState(false);
  const [etgSendingStatus, setEtgSendingStatus] = useState(
    etgBtn ? "Succsess" : "",
  );

  useEffect(() => {
    if (open) {
      setEtgBtn(false);
      const filterResult = rfqList.filter(
        (rfq) => Number(rfq.rfq_id) === Number(sessionStorage.getItem("rfqId")),
      );
      if (
        filterResult[0]?.status === "Booked" ||
        filterResult[0]?.status === "Confirmed & Booked"
      ) {
        setEtgBtn(true);
        setStartETGSending(true);
        setEtgSendingStatus("success");
      } else {
        setEtgBtn(false);
        setStartETGSending(false);
        setEtgSendingStatus("");
      }
    }
  }, [open, props.data]);

  const saveTradesInDb = async () => {
    props.data[1].rfqBookingModeUpdatingRows = rfqBookingModeUpdatingRows;
    if (splitManner === "notional") {
      let allocationForDb = postTradeSplitArrValues.map((split) => {
        let obj = {
          id: split.id,
          client_selection: split.postTradeClientSplitId,
          pm_selection: split.postTradePmSplitId,
          ...split,
        };
        delete obj.postTradeSplitPmSplitId;
        delete obj.postTradeClientSplitId;
        return obj;
      });
      props.data[1].confo_details.split_allocation = allocationForDb;
    }
    try {
      let saveTrades = await axios.post(
        `${process.env.REACT_APP_BASE_URL}` + `trades`,
        { rfq_object: props.data[1] },
        { headers: { Authorization: token } },
      );
      // dispatch(rfqSlice.getCheckedRfq(props.data[1].rfq_id));

      dispatch(
        rfqSlice.updateRfqStatus({
          status: saveTrades.data.status,
          rfq_id: saveTrades.data.rfq_id,
          current_rfq_id: props.data[1].rfq_id,
          data: saveTrades.data.data,
        }),
      );
    } catch (error) {
      console.log(error);
    }
  };
  const book_trade_firm = async () => {
    if (process.env.REACT_APP_ENVIRONMENT === "prod") {
      setEtgSendingStatus("start");
      setStartETGSending(true);
      try {
        let etgBooking = await axios.post(
          `${process.env.REACT_APP_BASE_URL}` + `trades/book_to_etg`,
          {
            confo_details: props.data[2],
            opts_data: props.data[3],
          },
          { headers: { Authorization: token } },
        );
        let hedgeType = props.data[1]?.rfq_table_data.find(
          (d) => d["0"] === "HEDGE_TYPE",
        )["1"][0];
        let dealType = props.data[1]?.rfq_table_data.find(
          (d) => d["0"] === "DEAL_TYPE",
        )["1"][0];
        if (bookToETG(etgBooking, hedgeType, dealType) === "Failed") {
          setEtgSendingStatus("fail");
          let allFailMessages;
          if (etgBooking.data.options.toString().includes("DOCTYPE")) {
            allFailMessages = "ETG SERVER ERROR";
          } else if (
            etgBooking.data.options.length === 0 &&
            etgBooking.data.hedges.length === 0
          ) {
            allFailMessages = "Both Options and hedges are empty";
          } else {
            allFailMessages =
              "Options: " +
              etgBooking?.data?.options
                ?.filter((opt) => opt.includes("Failed"))
                .toString() +
              "Hedges: " +
              etgBooking?.data?.hedges
                ?.filter((hedge) => hedge.includes("Failed"))
                .toString();
          }
          dispatch(actionSnackBar.setSnackBar("error", allFailMessages, 8000));
          setTimeout(() => {
            setStartETGSending(false);
            setEtgSendingStatus("");
          }, [4000]);
        } else {
          let allDealIds =
            "Options: " +
            etgBooking?.data?.options?.toString() +
            "Hedges: " +
            etgBooking?.data?.hedges?.toString();
          setTimeout(() => {
            setEtgSendingStatus("success");
            dispatch(actionSnackBar.setSnackBar("success", allDealIds, 8000));
          }, [2000]);
          setEtgBtn(true);
          saveTradesInDb();
        }
      } catch (error) {
        console.log(error);
        setEtgSendingStatus("fail");
        setTimeout(() => {
          setStartETGSending(false);
          setEtgSendingStatus("");
        }, [2000]);
        dispatch(actionSnackBar.setSnackBar("error", "Server Error", 5000));
      }
    } else {
      setEtgBtn(true);
      setEtgSendingStatus("success");
      saveTradesInDb();
    }
  };
  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose();
      }}
      classes={{ paper: classes.dialogPaperBookTrade }}
      PaperProps={{
        style: {
          backgroundColor: "#2D2D2D",
        },
      }}
    >
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        style={{ padding: "30px 84px 32px 75px", position: "absolute" }}
      >
        <Grid item xs={11}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs={6}>
              <Typography
                style={{
                  fontSize: "1.5rem",
                  color: "#ffffff",
                  position: "absolute",
                  left: "50px",
                  top: "25px",
                }}
              >
                Book Trades
              </Typography>
            </Grid>
            <Grid item xs={6} container justifyContent="flex-end">
              <IconButton
                size="small"
                onClick={() => {
                  handleClose();
                }}
                style={{
                  backgroundColor: "#3a3c3f",
                  left: "100px",
                }}
              >
                <CloseIcon
                  style={{ color: "#bababa", fontSize: "1.1875rem" }}
                />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={10} style={{ paddingTop: 10 }}>
          <div
            style={{
              borderRadius: "4px",
              height: "600px",
              width: "750px",
              backgroundColor: "#222222",
              position: "absolute",
              left: "50px",
              overflow: "scroll",
              overflowX: "hidden",
            }}
          >
            <Grid>
              <Grid item xs={6}>
                <Typography
                  style={{
                    fontSize: "1.3125rem",
                    color: "#868DA2",
                    width: "100px",
                    marginLeft: "9px",
                    paddingBlock: "4px",
                  }}
                >
                  Options
                </Typography>
                <Divider
                  variant="middle"
                  className={classes.dividerBookTrade}
                />
              </Grid>

              <Grid
                container
                style={{ marginTop: "20px", flexWrap: "wrap" }}
                xs={12}
                justifyContent={"flex-start"}
              >
                {data?.options.map((option) => {
                  const keys = Object.keys(option);
                  const cell = keys.map((key) => {
                    return (
                      <TableRow>
                        <td
                          style={{
                            textAlign: "left",
                            color: "#989EA8",
                          }}
                        >
                          {option[`${key}`] !== "" &&
                          key.toUpperCase() !== "ETG_JSON_STRING"
                            ? `${key.toUpperCase()}`
                            : null}
                        </td>
                        <td
                          style={{
                            textAlign: "right",
                            color: "#FFFFFF",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "noWrap",
                          }}
                        >
                          {key === "quantity" && option[`${key}`] !== "" ? (
                            <NumberFormat
                              thousandsGroupStyle="thousand"
                              value={option[`${key}`]}
                              decimalSeparator="."
                              displayType="text"
                              type="text"
                              thousandSeparator={true}
                              allowNegative={true}
                              decimalScale={4}
                            />
                          ) : option[`${key}`] !== "" &&
                            key.toUpperCase() !== "ETG_JSON_STRING" ? (
                            option[`${key}`]
                          ) : null}
                        </td>
                      </TableRow>
                    );
                  });
                  return (
                    <Grid
                      item
                      // xs={xsValue}
                      style={{
                        float: "left",
                        marginBottom: "30px",
                      }}
                    >
                      <Table
                        size="medium"
                        style={{
                          width: "150px",
                          margin: "0px 0px 2px 10px",
                        }}
                      >
                        <TableBody>{cell}</TableBody>
                      </Table>
                    </Grid>
                  );
                })}
              </Grid>
              <Grid item xs={6}>
                <Typography
                  style={{
                    fontSize: "1.3125rem",
                    color: "#868DA2",
                    width: "100px",
                    marginLeft: "9px",
                    paddingBottom: "5px",
                  }}
                >
                  Hedges
                </Typography>
                <Divider
                  variant="middle"
                  className={classes.dividerBookTrade}
                />
              </Grid>
              <Grid
                container
                style={{ marginTop: "20px", flexWrap: "wrap" }}
                justifyContent={"flex-start"}
              >
                {data?.hedges.map((hedges) => {
                  const keys = Object.keys(hedges);
                  const cell = keys.map((key) => {
                    return (
                      <TableRow>
                        <td
                          style={{
                            textAlign: "left",
                            color: "#989EA8",
                          }}
                        >
                          {hedges[`${key}`] !== "" &&
                          key.toUpperCase() !== "ETG_JSON_STRING"
                            ? `${key.toUpperCase()}`
                            : null}
                        </td>
                        <td
                          style={{
                            textAlign: "right",
                            color: "#FFFFFF",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "noWrap",
                          }}
                        >
                          {hedges[`${key}`] !== "" &&
                          key.toUpperCase() !== "ETG_JSON_STRING" ? (
                            key === "quantity" ? (
                              <NumberFormat
                                thousandsGroupStyle="thousand"
                                value={hedges[`${key}`]}
                                decimalSeparator="."
                                displayType="text"
                                type="text"
                                thousandSeparator={true}
                                allowNegative={true}
                                decimalScale={4}
                              />
                            ) : (
                              hedges[`${key}`]
                            )
                          ) : null}
                        </td>
                      </TableRow>
                    );
                  });
                  return (
                    <Grid item style={{ float: "left", marginBottom: "10px" }}>
                      <Table
                        size="medium"
                        style={{ width: "150px", margin: "0px 0px 10px 10px" }}
                      >
                        <TableBody>{cell}</TableBody>
                      </Table>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
      <Grid
        item
        container
        justifyContent="space-between"
        xs={12}
        alignItems="flex-end"
        style={{
          position: "absolute",
          bottom: "50px",
          backgroundColor: "transparent",
          width: "470px",
          marginLeft: "200px",
        }}
      >
        <Grid item>
          <Button
            variant="contained"
            className={classes.postTradeBtn}
            onClick={() => {
              handleClose();
            }}
            style={{ width: "150px", height: 30, backgroundColor: "#2692ff" }}
          >
            Cancel
          </Button>
        </Grid>
        <Grid item>
          <Grid item container>
            <Grid item>
              <IconButton
                disabled={startETGSending || etgBtn}
                onClick={book_trade_firm}
                // onClick={saveTradesInDb}z
                style={{
                  height: 30,
                  width: 150,
                  backgroundColor: "#2692ff",
                  color: "#ffffff",
                  borderRadius: 4,
                }}
              >
                <Grid
                  container
                  justifyContent="space-evenly"
                  alignItems="center"
                >
                  <Grid item xs={1}>
                    {startETGSending ? (
                      <SuccessFailAnimation
                        padding={20}
                        status={etgSendingStatus}
                        loaderClass="loaderSmall"
                      />
                    ) : (
                      // <SendIcon style={{paddingTop :3 , paddingRight :  12}}/>
                      <img
                        src={etg_logo}
                        style={{
                          marginTop: "4px",
                          marginLeft: "-1px",
                          position: "relative",
                          width: "20px",
                        }}
                        alt="logo"
                      />
                    )}
                  </Grid>
                  <Grid item xs={8} style={{ marginTop: 0 }}>
                    {etgSendingStatus !== "start" ? (
                      <Typography style={{ fontSize: "1rem" }}>
                        {!etgBtn && !startETGSending
                          ? "Send to ETG"
                          : etgSendingStatus === "success"
                          ? "Sent"
                          : etgSendingStatus === "fail"
                          ? "Failed"
                          : "Send"}
                      </Typography>
                    ) : (
                      <div className="sendingAnimETG">Sending</div>
                    )}
                  </Grid>
                </Grid>
              </IconButton>
            </Grid>
          </Grid>

          {/* <Button
            disabled={etgBtn}
            onClick={saveTradesInDb}
            type="submit"
            variant="contained"
            className={clsx(
              { [classes.postTradeBtn]: !etgBtn },
              {
                ["disabledEtgBtn"]: etgBtn,
              },
            )}
            style={{ width: "150px" }}
          >
            {etgBtn ? "Sent" : "Send To ETG"}
          </Button> */}
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default RfqBookModal;
