import React, { useRef, useEffect, useState, Fragment } from "react";
import jspreadsheet from "jspreadsheet-ce";
import {
  booking_mode_hidden_rows,
  post_trade_rows,
  tableHeaders,
  jtable_keys,
  booking_rows_attention,
  NDF_CCY,
  G10_CCY,
  user_editable_cells,
  spacebar_keys,
  market_data_keys,
  swap_keys,
  booking_mode_swp_hidden_rows,
  rfq_view_not_swp_hidden_rows,
} from "../rfqWorkspace/helpers/staticDataTable";
import { Typography, Grid, IconButton } from "@material-ui/core";
import alphabetJson from "../rfqWorkspace/helpers/Alphabet.json";
import * as dropDownList from "../rfqWorkspace/helpers/dropDownList";
import * as rfqPostTradeAction from "../../../../store/rfq/rfqPostTradeSlice";
import cssHighLightRowHelper from "../rfqWorkspace/helpers/cssHighLiteRow";
import moment from "moment";
import fwd_scale from "../../rfq/rfqWorkspace/helpers/FwdScale.json";
import { ReactComponent as NewColumn } from "../../../../assets/new column.svg";
import { ReactComponent as Launch } from "../../../../assets/launch.svg";
import { ReactComponent as DeleteColumn } from "../../../../assets/delete column.svg";
import { ReactComponent as RefreshTable } from "../../../../assets/refresh table.svg";
import { ReactComponent as Lock } from "../../../../assets/lock.svg";
import { ReactComponent as Comment } from "../../../../assets/comment.svg";
import { ReactComponent as Trash } from "../../../../assets/trash.svg";
import { ReactComponent as Arrows } from "../../../../assets/arrows.svg";
//arrows
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@mui/system";
import { Button } from "@material-ui/core";
import * as rfqSlice from "../../../../store/rfq/rfqSlice";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import "../../../../../node_modules/jspreadsheet-ce/dist/jspreadsheet.css";
import "./RfqWorkspaceTable.css";
import ModalCreate from "../../modals/traderNote/TraderNoteModal";
import * as uiSlice from "../../../../store/ui/uiSlice";
import { handleRfqWorkSpaceTableData } from "../../../../services/rfqWorkspace/initialRfqWorkSpaceTable";
import { RfqInfoModal } from "../../modals/rfqInfoModal/RfqInfoModal";
import { END_POINT } from "../../../../utils";
import * as rfqAggregatorSlice from "../../../../store/rfq/rfqAggregatorSlice";
import workerInstances from "../../../../services/index";
import * as regionUpdateHandlers from "../../../../services/rfqWorkspace/regionUpdateHandlers";
import * as regionFormat from "../../../../services/rfqWorkspace/regionFormat";
import * as regionTableModifiers from "../../../../services/rfqWorkspace/regionTableModifiers";
import * as regionApiCalls from "../../../../services/rfqWorkspace/regionApiCalls";
import * as regionEventListeners from "../../../../services/rfqWorkspace/regionEventListeners";
import * as regionTableUpdate from "../../../../services/rfqWorkspace/regionTableUpdate";
import * as actionSnackBar from "../../../../store/snackbar/action";
import { apiGetQuoatParameters } from "../../../../services/apiCalls/restCalls/restQuoteBookApi";
import {
  apiRefreshQuoteBook,
  buildOrderBook,
} from "../../../../services/apiCalls/quotsBookApi";
import { createQuoteBookDict } from "../../../../services/rfqWorkspace/createQuoteBookDict";
import {
  ws,
  connectWS,
  sendEvent,
  closeWS,
} from "../../../../services/websocket";
import { endOfQuarter } from "date-fns";

export const RfqWorkspaceTable = ({
  rfq_object,
  setrfq_object,
  setButtonBackgrounds,
}) => {
  let dealStatsData = useSelector((state) => state.rfqSlice.dealStatsData);
  let jRef = useRef(null);
  const dispatch = useDispatch();
  const [launchStatus, setLaunchStatus] = useState(false);
  let [isUserChangeBarrier, setIsUserChangeBarrier] = useState(false);
  const [newCreatedRfq, setNewCreatedRfq] = useState([]);
  const classes = useStyles();
  let cross_list_data = useSelector(
    (state) => state.rfqSlice.cross_list_data.items,
  );
  const singleCurrenciesList = useSelector(
    (state) => state.rfqSlice.singleCurrenciesList.items,
  );
  let hidden_keys = useSelector((state) => state.rfqSlice.hidden_keys);
  let pb = useSelector((state) => state.rfqSlice.pb_accounts.items);
  const modalCrateStatus = useSelector((state) => state.uiSlice.modalCreate);
  let traderNote = useSelector((state) => state.rfqSlice.traderNote);
  let traderId = sessionStorage.getItem("traderId");
  const rfqObjectUpdate = useSelector(
    (state) => state.rfqSlice.rfqObjectUpdate,
  );
  const clientsList = useSelector((state) => state.rfqSlice?.clientsList.items);
  const [alphabet, setalphabet] = useState(alphabetJson.alphabet);
  const activeRfqInstanceList = useSelector(
    (state) => state.rfqSlice.rfqInstanceList,
  );
  const deal_specs = useSelector((state) => state.rfqSlice.dealTypes.items);
  const status = useSelector((state) => state.rfqSlice.rfqButtonStatus);
  const rfq = useSelector((state) => state.rfqSlice.rfqId.items);
  const rfqs = useSelector((state) => state.rfqSlice.rfq);
  // const rfqMode = useSelector((state) => state.rfqSlice.rfqMode);
  const rfqMode = sessionStorage.getItem("rfqMode");
  const isRfqStatusUpdateLive = useSelector(
    (state) => state.rfqSlice.isRfqStatusUpdateLive,
  );
  const rfqId = sessionStorage.getItem("rfqId");
  const dealStateData = useSelector((state) => state.rfqSlice.dealStateData);
  const [clientPrice, setClientPrice] = useState("");
  const [marketPrice, setMarketPrice] = useState("");
  const [clientPremium, setClientPremium] = useState("");
  const [marketPremium, setMarketPremium] = useState("");
  const [premiumTypeSelectio, setPremiumTypeSelectio] = useState("");
  const [premiumValSelectio, setPremiumValSelectio] = useState("");
  const [premiumCcyTypeSelection, setPremiumCcyTypeSelection] = useState("");

  const [netClientPrice, setNetClientPrice] = useState("");
  const [netMarketPrice, setNetMarketPrice] = useState("");
  const [currentRfqId, setCurrentRfqId] = useState(0);
  let newRfqCreatedDetails = [];
  const [is_custom_deal, setIs_custom_deal] = useState(false);
  const [second_last_column, setSecond_last_column] = useState(0);
  let tableData = useSelector((state) => state.rfqSlice.tableData);
  let recommendationRfqs = useSelector(
    (state) => state.rfqSlice.recommendationRfqs,
  );
  const {
    postTradeSplitArrValues,
    postTradeSplitArrPreValues,
    currentMarketMaker,
    makorHSBCClient,
  } = useSelector((state) => state.rfqPostTradeSlice);
  let rfqDealType = sessionStorage.getItem("rfqDealType");
  let { volLegParams } = useSelector((state) => state.rfqAggregatorSlice);

  const rfqQuoteParameters = useSelector(
    (state) => state.rfqAggregatorSlice.RfqQuoteParameters,
  );
  let rfqBookingModeUpdatingRows = useSelector(
    (state) => state.rfqSlice.rfqBookingModeUpdatingRows,
  );
  let col = 0;
  let row = 0;

  let filterVolLegParams = [];
  if (rfq.length > 0) {
    filterVolLegParams = volLegParams.filter(
      (params) => Number(params.rfq_id) === Number(rfq[0].rfq_id),
    );
  }

  const handleClientList = () => {
    const uniqueClients = Array.from(
      new Set(clientsList.map((key) => key.value)),
    ).map((nameValue) => {
      return clientsList.find((name) => name.value === nameValue);
    });
    let arrangeClientList = uniqueClients.map((key) => {
      return key.value;
    });
    return arrangeClientList;
  };
  const table_keys = () => {
    return jtable_keys;
  };
  const keyRow = (key) => {
    return jtable_keys.indexOf(key);
  };
  const keyCol = () => {
    return tableHeaders.indexOf("KEY");
  };
  const keyVal = (key) => {
    let value = jRef.current?.jspreadsheet.getValueFromCoords(col, keyRow(key));
    if (Array.isArray(value)) {
      return value[0];
    }
    return value;
  };
  const getCell = (col, row) => {
    let id = jspreadsheet.getColumnNameFromId([col, row]);
    let cell = jRef.current.jspreadsheet.getCell([id]);
    return cell;
  };
  //update the table
  let createBookingModeData = {};

  const updateWindowOptionsBSD = async (cross, expiry_text) => {
    try {
      let response = await axios.post(
        `${process.env.REACT_APP_PYTON}` + `initialize_rfq`,
        { cross: cross, expiry_text: expiry_text },
      );
      let results = response.data;
      for (
        let index = 1;
        index < jRef.current.jspreadsheet.headers.length;
        index++
      ) {
        setCellValue(
          moment(new Date()).format("DD-MMM-YY"),
          "BARRIER_START_DATE",
          index,
        );
        setCellValue(results.expiry, "BARRIER_END_DATE", index);
      }
    } catch (error) {}
  };

  const update_controller = async (instance, cell, x, y, value) => {
    let updateKeyRfq = getCell(0, y)
      .innerText.toLowerCase()
      .replaceAll(" ", "_");
    // ! for window options

    if (value) {
      if (
        sessionStorage.getItem("rfqId") !== null ||
        sessionStorage.getItem("rfqId") !== undefined ||
        sessionStorage.getItem("rfqId") !== ""
      ) {
        setLaunchStatus(false);
        dispatch(rfqSlice.updateRqObjectUpdate(true));
      }
    }
    if (row === keyRow("CCY_PAIR")) {
      regionTableUpdate.update_ccy_pair(
        col,
        keyVal_input_col,
        checkCurrencyPair,
        setCellValue,
        clear_existing_grid,
        set_keyval_for_all_columns,
        jRef,
        actionSnackBar.setSnackBar,
        dispatch,
      );
    }
    if (row === keyRow("DEAL_TYPE")) {
      regionTableUpdate.update_deal_type(
        col,
        keyVal_input_col,
        clear_existing_grid,
        initialize_deal_type,
        handle_visible_rows,
        set_is_custom_deal_bool,
        setCellValue,
      );
    }
    if (row === keyRow("LEG_TYPE")) {
      regionTableUpdate.update_leg_type(
        col,
        keyVal_input_col,
        get_deal_specs_by_leg_type,
        setCellValue,
        jRef,
        set_custom_deal_type,
        is_vanilla_only,
        set_keyval_for_all_columns,
        handle_visible_rows,
        actionSnackBar.setSnackBar,
        dispatch,
        set_swap_leg,
        format_table,
      );
    }
    if (row === keyRow("EXPIRY_TEXT")) {
      update_expiry_text(col);
    }
    if (row === keyRow("STRIKE")) {
      if (
        row === keyRow("BARRIER_START_DATE") ||
        row === keyRow("BARRIER_END_DATE")
      ) {
        setIsUserChangeBarrier(true);
        jspreadsheet.isUserChangeBarrier = true;
      }
      if (!test_numeric_input("STRIKE", col)) {
        return;
      }
      regionTableUpdate.update_strike_price(
        col,
        keyVal_input_col,
        setCellValue,
        assign_jexcel_data,
        value,
      );
    }
    if (updateKeyRfq === "request_for") {
      for (let c = 1; c < jRef.current.jspreadsheet.headers.length; c++) {
        setCellValue(value, "REQUEST_FOR", c + 1);
      }
    }
    if (row === keyRow("BUY_SELL")) {
      regionTableUpdate.set_direction(col, keyVal_input_col, jRef, table_keys);
      if (sessionStorage.getItem("rfqMode") === "rfq booking view") {
        await refresh_opts_dets_from_api(true, col);
        calc_net_price(true);
        calc_net_price(false);
      }
    }
    if (row === keyRow("PB_ACCOUNT")) {
      for (let c = 1; c < jRef.current.jspreadsheet.headers.length; c++) {
        setCellValue(value, "PB_ACCOUNT", c + 1);
      }
    }
    if (row == keyRow("CALL_PUT")) {
      regionTableUpdate.update_call_put(
        col,
        keyVal_input_col,
        are_option_types_the_same,
        setCellValue,
        get_deal_specs,
        set_custom_deal_type,
      );
      if (sessionStorage.getItem("rfqMode") === "rfq booking view") {
        await refresh_opts_dets_from_api(true, col);
        calc_net_price(true);
        calc_net_price(false);
      }
    }
    if (row === keyRow("NOTIONAL")) {
      if (!test_numeric_input("NOTIONAL", col)) {
        return;
      }
      if (sessionStorage.getItem("rfqMode") === "rfq booking view") {
        dispatch(
          actionSnackBar.setSnackBar(
            "error",
            `User Premiums will be overwritten!`,
            5000,
          ),
        );
        await get_opts_dets_from_api(true, col);
        calc_net_price(true);
        calc_net_price(false);
      }
    }
    if (row === keyRow("BARRIER_START_DATE")) {
      let val = regionFormat.format_user_date(
        keyVal("BARRIER_START_DATE"),
        moment,
      );
      setCellValue(val, "BARRIER_START_DATE", col);
    }
    if (row === keyRow("BARRIER_END_DATE")) {
      let val = regionFormat.format_user_date(
        keyVal("BARRIER_END_DATE"),
        moment,
      );
      setCellValue(val, "BARRIER_END_DATE", col);
    }
    if (row === keyRow("EXPIRY")) {
      let val = regionFormat.format_user_date(keyVal("EXPIRY"), moment);
      setCellValue(val, "EXPIRY", col);
    }
    if (row === keyRow("SPOT_REF")) {
      if (!test_numeric_input("SPOT_REF", col)) {
        return;
      }
      set_keyval_for_all_columns("SPOT_REF", col);
    }
    if (row === keyRow("FWD_REF")) {
      if (!test_numeric_input("FWD_REF", col)) {
        return;
      }
      set_fwd_ref_same_expiry(col);
    }
    if (row === keyRow("CUT")) {
      set_keyval_for_all_columns("CUT", col);
      if (keyVal_input_col("CCYPAIR_GROUP", col) === "G10") {
        try {
          let response = await axios.post(
            `${process.env.REACT_APP_PYTON}` + `get_fixing_ref`,
            { cross: keyVal_input_col("CCY_PAIR", col), cut: value },
          );
          setCellValue(response.data.cut, "CUT", col);
          setCellValue(response.data.fixing_ref, "FIXING_REF", col);
        } catch (error) {
          console.log(error);
        }
      }
    }
    if (row === keyRow("FIXING_REF")) {
      set_keyval_for_all_columns("FIXING_REF", col);
    }
    if (row === keyRow("RFQ_FORMAT")) {
      await regionApiCalls.set_quote_type_api(jRef, axios, rfq_object);
      set_keyval_for_all_columns("RFQ_FORMAT", col);

      if (keyVal("RFQ_FORMAT") === "PCT_NOTIONAL") {
        setCellValue("YES", "INCLUDE_REF", col);
        set_keyval_for_all_columns("INCLUDE_REF", col);
      } else {
        setCellValue("NO", "INCLUDE_REF", col);
        set_keyval_for_all_columns("INCLUDE_REF", col);
      }
    }
    if (row === keyRow("BBG_MID_VOL")) {
      await get_opts_dets_from_api(true, col);
    }
    if (row === keyRow("REQUEST_FOR")) {
    }
    if (row === keyRow("PREMIUM_VAL")) {
      if (rfqMode === "rfq booking view") {
        assign_jexcel_data();
      }
      setPremiumValSelectio(value);
    }

    if (row === keyRow("PREMIUM_TYPE")) {
      setPremiumTypeSelectio(value);
      regionTableUpdate.update_premium_type(
        col,
        keyVal_input_col,
        setCellValue,
        set_keyval_for_all_columns,
        assign_jexcel_data,
      );
      await get_opts_dets_from_api(true, col);
    }
    if (updateKeyRfq === "hedge_type") {
      await refresh_hedge_amounts();
    }
    if (updateKeyRfq === "client_hedge_type") {
      await refresh_client_hedge_amounts();
    }
    if (row === keyRow("MM_HEDGE_TYPE")) {
      await refresh_mm_hedge_amounts();
    }
    if (row == keyRow("CLIENT_PRICE")) {
      update_price(col, true);
      calc_net_price(true);
    }
    if (row == keyRow("MARKET_PRICE")) {
      update_price(col, false);
      calc_net_price(false);
    }
    if (updateKeyRfq === "hedge_delta" || updateKeyRfq === "hedge_rate") {
      assign_jexcel_data();
    }
    if (row === keyRow("HEDGE_VAL")) {
      let val = regionFormat.format_user_date(keyVal("HEDGE_VAL"), moment);
      setCellValue(val, "HEDGE_VAL", col);
    }
    if (row === keyRow("DELIVERY")) {
      let val = regionFormat.format_user_date(keyVal("DELIVERY"), moment);
      setCellValue(val, "DELIVERY", col);
    }
    if (row === keyRow("SPOT_DATE")) {
      let val = regionFormat.format_user_date(keyVal("SPOT_DATE"), moment);
      setCellValue(val, "SPOT_DATE", col);
      set_keyval_for_all_columns("SPOT_DATE", col);
    }

    if (row === keyRow("HEDGE_MATCH_TYPE")) {
      if (value === "UNMATCHED") {
        dispatch(rfqSlice.addHidenKey("HEDGE_TYPE"));
        dispatch(rfqSlice.addHidenKey("HEDGE_TYPE"));
        dispatch(rfqSlice.addHidenKey("HEDGE_VAL"));
        dispatch(rfqSlice.addHidenKey("HEDGE_RATE"));
        dispatch(rfqSlice.addHidenKey("HEDGE_DELTA"));

        dispatch(rfqSlice.removeHidenKey("CLIENT_HEDGE_TYPE"));
        dispatch(rfqSlice.removeHidenKey("CLIENT_HEDGE_VAL"));
        dispatch(rfqSlice.removeHidenKey("CLIENT_HEDGE_RATE"));
        dispatch(rfqSlice.removeHidenKey("CLIENT_HEDGE_DELTA"));

        dispatch(rfqSlice.removeHidenKey("MM_HEDGE_TYPE"));
        dispatch(rfqSlice.removeHidenKey("MM_HEDGE_VAL"));
        dispatch(rfqSlice.removeHidenKey("MM_HEDGE_RATE"));
        dispatch(rfqSlice.removeHidenKey("MM_HEDGE_DELTA"));
      } else if (value === "MATCHED") {
        dispatch(rfqSlice.addHidenKey("MM_HEDGE_TYPE"));
        dispatch(rfqSlice.addHidenKey("MM_HEDGE_VAL"));
        dispatch(rfqSlice.addHidenKey("MM_HEDGE_RATE"));
        dispatch(rfqSlice.addHidenKey("MM_HEDGE_DELTA"));

        dispatch(rfqSlice.addHidenKey("CLIENT_HEDGE_TYPE"));
        dispatch(rfqSlice.addHidenKey("CLIENT_HEDGE_VAL"));
        dispatch(rfqSlice.addHidenKey("CLIENT_HEDGE_RATE"));
        dispatch(rfqSlice.addHidenKey("CLIENT_HEDGE_DELTA"));

        dispatch(rfqSlice.removeHidenKey("HEDGE_TYPE"));
        dispatch(rfqSlice.removeHidenKey("HEDGE_VAL"));
        dispatch(rfqSlice.removeHidenKey("HEDGE_RATE"));
        dispatch(rfqSlice.removeHidenKey("HEDGE_DELTA"));
      }
      assign_jexcel_data();
    }
    if (row == keyRow("NEAR_NOTIONAL")) {
      update_swap_notional();
    }
    if (row == keyRow("NEAR_DATE")) {
      let val = regionFormat.format_user_date(keyVal("NEAR_DATE"), moment);
      setCellValue(val, "NEAR_DATE", col);
    }
    if (row == keyRow("FAR_DATE")) {
      let val = regionFormat.format_user_date(keyVal("FAR_DATE"), moment);
      setCellValue(val, "FAR_DATE", col);
    }
    if (row == keyRow("NEAR_FIXING_DATE")) {
      let val = regionFormat.format_user_date(
        keyVal("NEAR_FIXING_DATE"),
        moment,
      );
      setCellValue(val, "NEAR_FIXING_DATE", col);
    }
    if (row == keyRow("FAR_FIXING_DATE")) {
      let val = regionFormat.format_user_date(
        keyVal("FAR_FIXING_DATE"),
        moment,
      );
      setCellValue(val, "FAR_FIXING_DATE", col);
    }
    if (row == keyRow("NEAR_DATE_TEXT")) {
      update_swap_date_text("NEAR");
    }
    if (row == keyRow("FAR_DATE_TEXT")) {
      update_swap_date_text("FAR");
    }
    if (row == keyRow("CLIENT_START_RATE")) {
      update_start_rate();
      update_far_rate("CLIENT");
      update_far_rate("MARKET");
    }
    if (row == keyRow("MARKET_START_RATE")) {
      update_far_rate("MARKET");
    }
    if (row == keyRow("CLIENT_SWAP_PTS")) {
      update_far_rate("CLIENT");
    }
    if (row == keyRow("MARKET_SWAP_PTS")) {
      update_far_rate("MARKET");
    }
    if (row == keyRow("CLIENT_FAR_RATE")) {
      update_swap_pts("CLIENT");
    }
    if (row == keyRow("MARKET_FAR_RATE")) {
      update_swap_pts("MARKET");
    }
    if (row === keyRow("PREM_CCY_TYPE")) {
      if (value.toLowerCase() === "default") {
        dispatch(rfqSlice.addHidenKey("THIRD_CCY"));
        dispatch(rfqSlice.addHidenKey("FX_RATE"));
        for (
          let index = 0;
          index < jRef.current.jspreadsheet.headers.length - 1;
          index++
        ) {
          setCellValue("", "THIRD_CCY", index + 1);
          setCellValue(1, "FX_RATE", index + 1);
          update_price(index + 1, true);
          update_price(index + 1, false);
        }
        update_price(col, true);
        update_price(col, false);
      } else if (value.toLowerCase() === "third_ccy") {
        dispatch(rfqSlice.removeHidenKey("THIRD_CCY"));
        dispatch(rfqSlice.removeHidenKey("FX_RATE"));
      }
    }

    if (row === keyRow("THIRD_CCY")) {
      for (
        let index = 0;
        index < jRef.current.jspreadsheet.headers.length - 1;
        index++
      ) {
        setCellValue(value, "THIRD_CCY", index + 1);
      }
      try {
        if (value !== null && value !== "") {
          const premiumCcy = keyVal_input_col(`PREMIUM_CCY`, 1);
          const rates = await axios.get(
            `${process.env.REACT_APP_BLOOMBERG_DERIVS}spot/${
              premiumCcy + value
            }`,
          );
          for (
            let index = 0;
            index < jRef.current.jspreadsheet.headers.length - 1;
            index++
          ) {
            console.log({ response: rates.data.price });
            setCellValue(rates?.data.price, "FX_RATE", index + 1);
            update_price(index + 1, true);
            update_price(index + 1, false);
          }
        }
      } catch (error) {
        console.log(error);
      }

      assign_jexcel_data();
    }
    if (row === keyRow("FX_RATE")) {
      for (
        let index = 0;
        index < jRef.current.jspreadsheet.headers.length - 1;
        index++
      ) {
        if (!isNaN(Number(value))) {
          setCellValue(value, "FX_RATE", index + 1);
          update_price(index + 1, true);
          update_price(index + 1, false);
        }
      }
    }
    let rfqStatus = sessionStorage.getItem("rfqStatus");
    if (
      sessionStorage.getItem("rfqMode") === "rfq booking view" &&
      sessionStorage.getItem("rfqId") !== null
    ) {
      let rfqStatus = sessionStorage.getItem("rfqStatus");

      if (rfqStatus === "Live" || rfqStatus === "Confirmed") {
        assign_jexcel_data();
        let rfqObjectToUpdate = {
          rfq_id: sessionStorage.getItem("rfqId"),
          rfqObjectToUpdate: jRef.current.jspreadsheet.getJson(),
          rfqStatus: rfqStatus.status,
        };
        await updateRfqBookingModeDetails(rfqObjectToUpdate);
        // setSplitted(false);
        dispatch(rfqPostTradeAction.setIsSplitted(false));
        // if (postTradeSplitArrValues.length > 0) {
        //   resetPostTradePanel();
        // }
        dispatch(rfqPostTradeAction.ResetPostTradePanelForRfqWorkSpace());
      }
    }
  };

  const set_swap_leg = async (leg_type) => {
    if (leg_type === "SWAP") {
      if (keyVal_input_col("CCYPAIR_GROUP", 1) === "NDF") {
        setCellValue("YES", "DATES_IN_RFQ", 1);
        setCellValue("1m", "NEAR_DATE_TEXT", 1);
        setCellValue("0", "NEAR_SWAP", 1);
      } else {
        setCellValue("YES", "DATES_IN_RFQ", 1);
        setCellValue("SP", "NEAR_DATE_TEXT", 1);
        setCellValue("0", "NEAR_SWAP", 1);
      }
      await initialize_swap();
    }
  };
  const initialize_swap = async () => {
    let cross = keyVal_input_col("CCY_PAIR", 1);
    const swap_date_text = keyVal_input_col(`NEAR_DATE_TEXT`, 1);
    const ccyPairGroup = keyVal_input_col("CCYPAIR_GROUP", 1);

    try {
      let response = await axios.get(
        `${process.env.REACT_APP_PYTON}` + `initialize_swap/${cross}`,
      );

      let spotRef = await axios.get(
        `${process.env.REACT_APP_BLOOMBERG}` + `spot/${cross}`,
      );

      if (ccyPairGroup === "NDF") {
        let swap_dates = await axios.get(
          `${process.env.REACT_APP_BLOOMBERG}` +
            `swap/${keyVal_input_col("CCY_PAIR", 1)}/${swap_date_text}`,
        );
        let getFixingDatas = await axios.post(
          `${process.env.REACT_APP_PYTON}` + `get_fixing_date`,
          {
            ccy_pair: keyVal_input_col("CCY_PAIR", 1),
            delivery_date: swap_dates.data.swap_date,
          },
        );
        setCellValue(swap_dates.data?.swap_points, "NEAR_SWAP", 1);
        setCellValue(swap_dates.data.swap_text, `NEAR_DATE_TEXT`, 1);
        setCellValue(swap_dates.data.swap_date, `NEAR_DATE`, 1);
        setCellValue(getFixingDatas.data.fixing_date, `NEAR_FIXING_DATE`, 1);
      } else {
        let spotDate = moment(spotRef.data.spot_date).format("DD-MMM-YY");
        setCellValue(spotDate, "NEAR_DATE", 1);
        // let spotResults = spotRef.data;
        // setCellValue(spotResults.spot_date, "NEAR_DATE", 1);
      }

      let fixingRefResults = response.data;

      let spotRefNumber = Number(spotRef.data.price);

      setCellValue(fixingRefResults.fixing_ref, "FIXING_REF", 1);
      setCellValue("SELL_BUY", "SWAP_DIRECTION", 1);

      setCellValue(spotRefNumber.toFixed(4), "SPOT_REF", 1);
      setCellValue(cross.substring(0, 3), "SWAP_CCY", 1);
      document.addEventListener("keydown", add_swap_ccy_toggle);

      if (keyVal_input_col("CCYPAIR_GROUP", col) === "G10") {
        document.addEventListener("keydown", add_cut_toggle_to_g10_ccy);
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  };
  const add_swap_ccy_toggle = (event) => {
    let ccy1 = keyVal_input_col("CCY_PAIR", 1).substring(0, 3);
    let ccy2 = keyVal_input_col("CCY_PAIR", 1).substring(3, 6);
    create_cell_toggle("SWAP_CCY", [ccy1, ccy2], true, event);
  };
  const update_far_rate = (client_market) => {
    let spot = keyVal_input_col(`${client_market}_START_RATE`, 1);
    let swap = keyVal_input_col(`${client_market}_SWAP_PTS`, 1);
    // let fwd_scale = this.fwd_scale[this.keyVal_input_col("CCY_PAIR", 1)];
    let far_rate = calc_outright_fwd(
      parseFloat(spot),
      parseFloat(swap),
    ).toPrecision(7);

    setCellValue(far_rate, `${client_market}_FAR_RATE`, 1);
  };
  const update_swap_pts = (client_market) => {
    let spot = keyVal_input_col(`${client_market}_START_RATE`, 1);
    let fwd = keyVal_input_col(`${client_market}_FAR_RATE`, 1);
    let fwd_scale = fwd_scale[keyVal_input_col("CCY_PAIR", 1)];
    let swap_pts = calc_swap_from_fwd(
      parseFloat(spot),
      parseFloat(fwd),
    ).toFixed(fwd_scale);
    setCellValue(parseFloat(swap_pts), `${client_market}_SWAP_PTS`, 1);
  };
  const calc_outright_fwd = (spot, swap) => {
    return (
      spot + swap / Math.pow(10, fwd_scale[keyVal_input_col("CCY_PAIR", 1)])
    );
  };
  const calc_swap_from_fwd = (spot, fwd) => {
    return (
      (fwd - spot) * Math.pow(10, fwd_scale[keyVal_input_col("CCY_PAIR", 1)])
    );
  };
  const update_swap_notional = () => {
    setCellValue(keyVal_input_col("NEAR_NOTIONAL", 1), "FAR_NOTIONAL", 1);
  };
  const update_start_rate = () => {
    setCellValue(
      keyVal_input_col("CLIENT_START_RATE", 1),
      "MARKET_START_RATE",
      1,
    );
  };

  const update_swap_date_text = async (NEAR_FAR) => {
    const swap_date_text = keyVal_input_col(`${NEAR_FAR}_DATE_TEXT`, 1);
    if (swap_date_text === "") {
      return;
    }
    try {
      let swap_dates = await axios.get(
        `${process.env.REACT_APP_BLOOMBERG}` +
          `swap/${keyVal_input_col("CCY_PAIR", 1)}/${swap_date_text}`,
      );
      let getFixingDatas = await axios.post(
        `${process.env.REACT_APP_PYTON}` + `get_fixing_date`,
        {
          ccy_pair: keyVal_input_col("CCY_PAIR", 1),
          delivery_date: swap_dates.data.swap_date,
        },
      );

      setCellValue(
        getFixingDatas.data.fixing_date,
        `${NEAR_FAR}_FIXING_DATE`,
        1,
      );
      setCellValue(swap_dates.data.swap_text, `${NEAR_FAR}_DATE_TEXT`, 1);
      setCellValue(swap_dates.data.swap_date, `${NEAR_FAR}_DATE`, 1);
      setCellValue(swap_dates.data.swap_points, `${NEAR_FAR}_SWAP`, 1);
    } catch (error) {
      console.log(error);
    } finally {
    }
  };
  const set_fwd_ref_same_expiry = (col) => {
    try {
      jRef.current.jspreadsheet.ignoreEvents = true;
      let fwd = keyVal_input_col("FWD_REF", col);
      let expiry = keyVal_input_col("EXPIRY", col);
      for (let c = 1; c < jRef.current.jspreadsheet.headers.length; c++) {
        if (keyVal_input_col("EXPIRY", c) === expiry) {
          jRef.current.jspreadsheet.setValueFromCoords(
            c,
            jtable_keys.indexOf("FWD_REF"),
            fwd,
            true,
          );
        }
      }
    } catch (err) {
    } finally {
      jRef.current.jspreadsheet.ignoreEvents = false;
    }
  };
  const refresh_hedge_amounts = async () => {
    const is_vanillas_only = is_vanilla_only();

    for (
      let index = 1;
      index < jRef.current.jspreadsheet.headers.length;
      index++
    ) {
      if (index !== 1) {
        setCellValue("", "HEDGE_TYPE", index);
      }
      setCellValue("", "HEDGE_DELTA", index);
      setCellValue("", "HEDGE_RATE", index);
      setCellValue("", "HEDGE_VAL", index);
    }

    if (keyVal_input_col("HEDGE_TYPE", 1) === "LIVE") {
      return;
    }
    for (
      let index = 0;
      index < jRef.current.jspreadsheet.headers.length - 1;
      index++
    ) {
      let table_data = jRef.current.jspreadsheet.getJson();
      try {
        let response = await axios.post(
          `${process.env.REACT_APP_PYTON}` + `refresh_hedge_amounts`,
          {
            opts_data: table_data,
            is_vanillas_only: is_vanillas_only,
          },
        );
        let results = response.data;
        for (let idx = 0; idx < results.hedges.hedge_delta.length; idx++) {
          // setCellValue(rfq[index].hedge_type, "CLIENT_HEDGE_TYPE",index + 1);
          setCellValue(results.hedges.hedge_delta[idx], "HEDGE_DELTA", idx + 1);
          setCellValue(results.hedges.hedge_rate[idx], "HEDGE_RATE", idx + 1);
          setCellValue(results.hedges.hedge_val[idx], "HEDGE_VAL", idx + 1);
        }
        assign_jexcel_data();
      } catch (error) {
        console.log(error);
      }
    }
  };
  const refresh_mm_hedge_amounts = async () => {
    const is_vanillas_only = is_vanilla_only();
    for (
      let index = 1;
      index < jRef.current.jspreadsheet.headers.length;
      index++
    ) {
      if (index !== 1) {
        setCellValue("", "MM_HEDGE_TYPE", index);
      }
      setCellValue("", "MM_HEDGE_DELTA", index);
      setCellValue("", "MM_HEDGE_RATE", index);
      setCellValue("", "MM_HEDGE_VAL", index);
    }

    if (keyVal_input_col("MM_HEDGE_TYPE", 1) === "LIVE") {
      return;
    }
    let table_data = jRef.current.jspreadsheet.getJson();
    try {
      let response = await axios.post(
        `${process.env.REACT_APP_PYTON}` + `refresh_hedge_amounts`,
        {
          opts_data: table_data,
          is_vanillas_only: is_vanillas_only,
        },
      );
      let results = response.data;

      for (let idx = 0; idx < results.hedges.hedge_delta.length; idx++) {
        setCellValue(
          results.hedges.mm_hedge_delta[idx],
          "MM_HEDGE_DELTA",
          idx + 1,
        );
        setCellValue(
          results.hedges.mm_hedge_rate[idx],
          "MM_HEDGE_RATE",
          idx + 1,
        );
        setCellValue(results.hedges.mm_hedge_val[idx], "MM_HEDGE_VAL", idx + 1);
      }
      assign_jexcel_data();
    } catch (error) {
      console.log(error);
    }
  };
  const refresh_client_hedge_amounts = async () => {
    const is_vanillas_only = is_vanilla_only();
    for (
      let index = 1;
      index < jRef.current.jspreadsheet.headers.length;
      index++
    ) {
      if (index !== 1) {
        setCellValue("", "CLIENT_HEDGE_TYPE", index);
      }
      setCellValue("", "CLIENT_HEDGE_DELTA", index);
      setCellValue("", "CLIENT_HEDGE_RATE", index);
      setCellValue("", "CLIENT_HEDGE_VAL", index);
    }

    if (keyVal_input_col("CLIENT_HEDGE_TYPE", 1) === "LIVE") {
      return;
    }
    let table_data = jRef.current.jspreadsheet.getJson();
    try {
      let response = await axios.post(
        `${process.env.REACT_APP_PYTON}` + `refresh_hedge_amounts`,
        {
          opts_data: table_data,
          is_vanillas_only: is_vanillas_only,
        },
      );
      let results = response.data;
      for (let idx = 0; idx < results.hedges.hedge_delta.length; idx++) {
        setCellValue(
          results.hedges.client_hedge_delta[idx],
          "CLIENT_HEDGE_DELTA",
          idx + 1,
        );
        setCellValue(
          results.hedges.client_hedge_rate[idx],
          "CLIENT_HEDGE_RATE",
          idx + 1,
        );
        setCellValue(
          results.hedges.client_hedge_val[idx],
          "CLIENT_HEDGE_VAL",
          idx + 1,
        );
      }
      assign_jexcel_data();
    } catch (error) {
      console.log(error);
    }
  };
  const update_price = (col, is_client_price) => {
    let premium_type = keyVal_input_col("PREMIUM_TYPE", col);
    let price_key = is_client_price ? "CLIENT_PRICE" : "MARKET_PRICE";
    let premium_key = is_client_price ? "CLIENT_PREMIUM" : "MARKET_PREMIUM";
    let premium = 0;
    let notional = keyVal_input_col("NOTIONAL", col);
    let price = keyVal_input_col(price_key, col);
    let strike = keyVal_input_col("STRIKE", col);

    let base_ccy = keyVal_input_col("CCY_PAIR", 1).substring(0, 3);
    let notional_ccy = keyVal_input_col("NOTIONAL_CCY", 1);
    const fx_rate = Number(keyVal_input_col("FX_RATE", 1));
    if (premium_type === "BASE_PCT") {
      if (base_ccy === notional_ccy) {
        premium = price * notional * 10000 * fx_rate;
      } else {
        premium = price * notional * strike * 10000 * fx_rate;
      }
    }

    if (premium_type === "TERMS_PIPS") {
      if (base_ccy === notional_ccy) {
        premium = price * notional * 1000000 * fx_rate;
      } else {
        premium = price * (notional * strike * 1000000) * fx_rate;
      }
    }

    if (premium_type === "TERMS_PCT") {
      if (base_ccy === notional_ccy) {
        premium = price * notional * strike * 10000 * fx_rate;
      } else {
        premium = price * notional * 10000 * fx_rate;
      }
    }

    if (premium_type === "BASE_PIPS") {
      if (base_ccy === notional_ccy) {
        premium = price * (notional * strike * 1000000) * fx_rate;
      } else {
        premium = price * (notional * 1000000) * fx_rate;
      }
    }

    if (is_client_price) {
      setClientPrice(price);
      setClientPremium(premium.toLocaleString());
    } else if (is_client_price === false) {
      setMarketPrice(price);
      setMarketPremium(premium.toLocaleString());
    }

    setCellValue(premium.toLocaleString(), premium_key, col);
    // assign_jexcel_data();
    // rfq_object.rfq_table_data = jRef.current.jspreadsheet.getJson()
    // rfq_object = {...rfq_object,rfq_table_data: jRef.current.jspreadsheet.getJson()};
  };
  const calc_net_price = (is_client_premium) => {
    let notional = keyVal_input_col("NOTIONAL", 1);
    let strike = keyVal_input_col("STRIKE", 1);
    let premium_key = is_client_premium ? "CLIENT_PREMIUM" : "MARKET_PREMIUM";
    let net_premium = 0;

    for (
      let index = 1;
      index < jRef.current.jspreadsheet.headers.length;
      index++
    ) {
      let opt_premium = keyVal_input_col(premium_key, index);

      let set_direction =
        keyVal_input_col("BUY_SELL", index) === "BUY" ? 1 : -1;
      net_premium += parseFloat(opt_premium.replace(/,/g, "")) * set_direction;
    }

    let premium_type = keyVal_input_col("PREMIUM_TYPE", 1);

    let price = 0;

    let premium = net_premium;

    if (premium_type === "BASE_PCT") {
      price = premium / (notional * 10000);
    }
    if (premium_type === "TERMS_PIPS") {
      price = premium / (notional * 1000000);
    }
    if (premium_type === "TERMS_PCT") {
      price = premium / (notional * strike * 10000);
    }
    if (premium_type === "BASE_PIPS") {
      price = premium / (notional * strike * 1000000);
    }
    let price_key = is_client_premium ? "NET_CLIENT_PRICE" : "NET_MARKET_PRICE";
    if (price_key === "NET_CLIENT_PRICE") {
      setCellValue(price, "NET_CLIENT_PRICE", 1);
    } else if (price_key === "NET_MARKET_PRICE") {
      setCellValue(price, "NET_MARKET_PRICE", 1);
    }
  };
  const are_option_types_the_same = () => {
    let option_types = [];
    for (
      let index = 1;
      index < jRef.current.jspreadsheet.headers.length;
      index++
    ) {
      option_types.push(keyVal_input_col("OPTION_TYPE", index));
    }
    const allEqual = (arr) => arr.every((v) => v === arr[0]);
    return allEqual(option_types);
  };
  const get_opts_dets_from_api = async (is_user_vol, col) => {
    const is_vanillas_only = is_vanilla_only();

    if (is_vanillas_only) {
      await get_vanilla_opt_dets(is_user_vol, col);
    } else {
      await regionApiCalls.get_exotic_opt_dets(col, jRef, setCellValue, axios);
    }
  };
  const get_vanilla_opt_dets = async (is_user_vol, col) => {
    // if (rfqMode === "rfq booking view") {
    try {
      for (
        let index = 0;
        index < jRef.current.jspreadsheet.headers.length - 1;
        index++
      ) {
        let strike = keyVal_input_col("STRIKE", index + 1);
        let expiry = keyVal_input_col("EXPIRY", index + 1);
        let ccyPair = keyVal_input_col("CCY_PAIR", index + 1);

        let getbbgMidVol = await axios.get(
          `${process.env.REACT_APP_BLOOMBERG}` +
            `strikevol/${ccyPair}/${expiry}/${strike}`,
        );
        let strikePrice = Number(getbbgMidVol.data.vol);
        setCellValue(strikePrice, "BBG_MID_VOL", index + 1);
      }

      let response = await axios.post(
        `${process.env.REACT_APP_PYTON}` + `get_vanilla_opt_dets`,
        {
          is_user_vol: is_user_vol,
          opts_data: jRef.current.jspreadsheet.getJson(),
        },
      );
      let results = response.data;

      const prem_ccy_type = keyVal_input_col("PREM_CCY_TYPE", col);
      const premium_ccy = keyVal_input_col("PREMIUM_CCY", col);
      const third_ccy = keyVal_input_col("THIRD_CCY", col);

      const getFx_rate = async (premium_ccy, third_ccy) => {
        try {
          const rates = await axios.get(
            `${process.env.REACT_APP_BLOOMBERG_DERIVS}spot/${
              premium_ccy + third_ccy
            }`,
          );
          return rates.data.price;
        } catch (error) {
          console.log(error);
        }
      };

      const fx_rate =
        prem_ccy_type.toLowerCase() !== "default"
          ? await getFx_rate(premium_ccy, third_ccy)
          : 1;
      for (
        let index = 0;
        index < jRef.current.jspreadsheet.headers.length - 1;
        index++
      ) {
        setCellValue(
          results.ui_dets[index].delta.toFixed(2),
          "DELTA",
          index + 1,
        );
        setCellValue(
          results.ui_dets[index].hedge_amount.toFixed(2),
          "HEDGE_AMOUNT",
          index + 1,
        );
        setCellValue(
          results.ui_dets[index].price_pct_notional.toFixed(4),
          "PRICE_PCT_NOTIONAL",
          index + 1,
        );
        setCellValue(results.ui_dets[index].vega.toFixed(0), "VEGA", index + 1);
        setCellValue(
          results.ui_dets[index].client_price.toFixed(6),
          "CLIENT_PRICE",
          index + 1,
        );
        setCellValue(
          results.ui_dets[index].market_price.toFixed(6),
          "MARKET_PRICE",
          index + 1,
        );

        setCellValue(
          (
            Number(results.ui_dets[index].client_premium) * Number(fx_rate)
          ).toLocaleString(),
          "CLIENT_PREMIUM",
          index + 1,
        );
        setCellValue(
          (
            Number(results.ui_dets[index].market_premium) * Number(fx_rate)
          ).toLocaleString(),
          "MARKET_PREMIUM",
          index + 1,
        );
        setCellValue("", "NET_CLIENT_PRICE", index + 1);
        setCellValue("", "NET_MARKET_PRICE", index + 1);
      }

      setCellValue(
        results.net_prices.net_client_price.toFixed(6),
        "NET_CLIENT_PRICE",
        1,
      );
      setCellValue(
        results.net_prices.net_market_price.toFixed(6),
        "NET_MARKET_PRICE",
        1,
      );
      for (
        let index = 1;
        index < jRef.current.jspreadsheet.headers.length;
        index++
      ) {
        if (index !== 1) {
          setCellValue("", "HEDGE_TYPE", index);
        }
        setCellValue("", "HEDGE_DELTA", index);
        setCellValue("", "HEDGE_RATE", index);
        setCellValue("", "HEDGE_VAL", index);
      }

      for (let idx = 0; idx < results.hedges.hedge_delta.length; idx++) {
        // let isHadgeTypeMatch = keyVal_input_col("STRIKE", index + 1);
        if (results.hedges.hedge_match_type === "") {
          setCellValue("MATCHED", "HEDGE_MATCH_TYPE", idx + 1);
        }
        if (results.hedges.hedge_match_type === "MATCHED") {
          setCellValue("MATCHED", "HEDGE_MATCH_TYPE", idx + 1);
        }
        if (results.hedges.hedge_match_type === "UNMATCHED") {
          setCellValue("UNMATCHED", "HEDGE_MATCH_TYPE", idx + 1);
        }
        setCellValue(results.hedges.hedge_delta[idx], "HEDGE_DELTA", idx + 1);

        setCellValue(results.hedges.hedge_rate[idx], "HEDGE_RATE", idx + 1);
        setCellValue(results.hedges.hedge_val[idx], "HEDGE_VAL", idx + 1);

        setCellValue(
          results.hedges.mm_hedge_delta[idx],
          "MM_HEDGE_DELTA",
          idx + 1,
        );
        setCellValue(
          results.hedges.mm_hedge_rate[idx],
          "MM_HEDGE_RATE",
          idx + 1,
        );
        setCellValue(results.hedges.mm_hedge_val[idx], "MM_HEDGE_VAL", idx + 1);
        setCellValue(
          results.hedges.client_hedge_delta[idx],
          "CLIENT_HEDGE_DELTA",
          idx + 1,
        );
        setCellValue(
          results.hedges.client_hedge_rate[idx],
          "CLIENT_HEDGE_RATE",
          idx + 1,
        );
        setCellValue(
          results.hedges.client_hedge_val[idx],
          "CLIENT_HEDGE_VAL",
          idx + 1,
        );
      }
    } catch (error) {}
    // }
  };
  const checkCurrencyPair = (ccyPair) => {
    return cross_list_data.find(({ value }) => value === ccyPair);
  };
  const selectionActive = (instance, x1, y1, x2, y2) => {
    let leg;
    let headers = jRef.current.jspreadsheet.headers;
    for (let col = 1; col < jRef.current.jspreadsheet.headers.length; col++) {
      for (
        let index = 0;
        index < jRef.current.jspreadsheet.rows.length - 1;
        index++
      ) {
        const cell = getCell(col, index);
        let id = jspreadsheet.getColumnNameFromId([col, index]);
        switch (col) {
          case 1:
            leg = "B";
            break;
          case 2:
            leg = "C";
            break;
          case 3:
            leg = "D";
            break;
          case 4:
            leg = "E";
            break;
          case 5:
            leg = "F";
            break;
          case 6:
            leg = "G";
            break;
          case 7:
            leg = "H";
            break;
          case 8:
            leg = "I";
            break;
          case 9:
            leg = "J";
            break;
          case 10:
            leg = "A";
            break;
          default:
            break;
        }
        if (rfq.length > 0) {
          handleReadOnlyClass(
            jRef.current.jspreadsheet.headers,
            jRef.current.jspreadsheet.rows,
          );
        }
      }
    }
    document.addEventListener("keydown", eventListeners);
    document.removeEventListener("keydown", auto_fill_dropdown_event);
    col = x1;
    row = y1;
    let key = jRef.current.jspreadsheet.getValueFromCoords(0, row);
    if (user_editable_cells.includes(key) && col > 0 && rfq.length === 0) {
      let cell = getCell(col, row);
      // cell.classList.remove("readonly");
    }
  };
  const blur = () => {
    jRef.current.jspreadsheet.updateSelectionFromCoords(0, 0, 0, 0);
  };
  const initialize_table = () => {
    let newArr = [];
    jtable_keys.forEach((element) => {
      newArr.push([element, ""]);
    });
    return newArr;
  };
  const hideIndex = () => {};
  const setReadOnly = () => {
    let columns = [];
    for (let c = 1; c < tableHeaders.length; c++) {
      columns.push({
        readOnly: true,
        align: "center",
      });
    }
    return columns;
  };
  const [options, setOptions] = useState({
    data: initialize_table(),
    colHeaders: tableHeaders,
    colWidths: [175, 175],
    tableHeight: "74vh",
    // tableHeight: status !== ""  || rfqMode === "" ? `70vh` : "100vh",
    tableWidth: "60vw",
    tableOverflow: true,
    columnSorting: false,
    allowInsertRow: false,
    allowInsertColumn: false,
    freezeColumns: 1,
    columns: setReadOnly(),
    onchange: update_controller,
    onselection: selectionActive,
    onblur: blur,
    col: col,
    row: row,
  });
  const initialize_deal_type = (col) => {
    const cross = keyVal_input_col("CCY_PAIR", col);
    const request_for = keyVal_input_col("REQUEST_FOR", col);
    if (cross === "") {
      dispatch(actionSnackBar.setSnackBar("error", "SELECT CROSS", 3000));
      setCellValue("", "DEAL_TYPE", col);
      return;
    }
    let specs = get_deal_specs(col);
    if (specs === undefined) {
      dispatch(
        actionSnackBar.setSnackBar(
          "error",
          `${keyVal_input_col("DEAL_TYPE", col)} is not a valid Deal type`,
          3000,
        ),
      );
      setCellValue("", "LEG_TYPE", col);
      return;
    } else {
      setCellValue(
        keyVal_input_col("DEAL_TYPE", col).toUpperCase(),
        "DEAL_TYPE",
        col,
      );

      let ccypair_group = get_ccypair_group(col);
      for (let index = 0; index < specs?.legs; index++) {
        let col_idx = index + 1;
        if (specs?.legs > jRef.current.jspreadsheet.headers.length - 1) {
          addNewColumn();
        }

        setCellValue(cross, "CCY_PAIR", col_idx);
        setCellValue(ccypair_group, "CCYPAIR_GROUP", col_idx);
        if (specs?.legs === 1 && specs.option_type[0] !== "SWAP") {
          sessionStorage.setItem("rfqDealType", specs?.option_type[0]);

          setCellValue(`SINGLE_${specs?.option_type[0]}`, "DEAL_TYPE", col_idx);
        } else {
          sessionStorage.setItem("rfqDealType", specs?.option_type[0]);
          setCellValue(
            keyVal_input_col("DEAL_TYPE", col),
            "DEAL_TYPE",
            col_idx,
          );
        }

        setCellValue(specs?.leg_type[index].toUpperCase(), "LEG_TYPE", col_idx);
        setCellValue(specs?.call_put[index], "CALL_PUT", col_idx);
        setCellValue(specs?.buy_sell[index], "BUY_SELL", col_idx);
        if (specs?.option_type[index] === "EXOTIC") {
          if (ccypair_group === "NDF") {
            setCellValue("FWD", "REF_TYPE", col_idx);
          } else {
            setCellValue("SPOT", "REF_TYPE", col_idx);
          }
        } else {
          setCellValue(specs?.ref_type, "REF_TYPE", col_idx);
        }

        setCellValue(specs?.include_ref, "INCLUDE_REF", col_idx);
        setCellValue(cross.substring(0, 3), "NOTIONAL_CCY", col_idx);
        setCellValue("OG-RBS", "PB_ACCOUNT", col_idx);
        setCellValue(specs?.rfq_format, "RFQ_FORMAT", col_idx);
        setCellValue("BASE_PCT", "PREMIUM_TYPE", col_idx);
        setCellValue(request_for, "REQUEST_FOR", col_idx);

        setCellValue(
          keyVal_input_col("CCY_PAIR", col_idx).substring(0, 3),
          "PREMIUM_CCY",
          col_idx,
        );
        setCellValue("SPOT", "PREMIUM_VAL", col_idx);
        setCellValue(specs?.option_type[index], "OPTION_TYPE", col_idx);
      }
      setCellValue("FWD", "HEDGE_TYPE", 1);
      setCellValue("FWD", "MM_HEDGE_TYPE", 1);
      setCellValue("FWD", "CLIENT_HEDGE_TYPE", 1);
      set_swap_leg(specs.leg_type[0]);
      const col_nums = jRef.current.jspreadsheet.headers.length;
      for (let index = 1; index < col_nums; index++) {
        if (index > specs?.legs) {
          jRef.current.jspreadsheet.deleteColumn(index);
        }
      }
      regionFormat.add_dropdown_class_to_deal_type(
        keyVal_input_col,
        getCell,
        keyRow,
      );
    }
    format_table();
  };
  const get_deal_specs = (col) => {
    let deal_type = keyVal_input_col("DEAL_TYPE", col);
    if (deal_type.includes("SINGLE") || deal_type.includes("CUSTOM")) {
      return deal_specs[keyVal_input_col("LEG_TYPE", col).toLowerCase()];
    } else {
      return deal_specs[keyVal_input_col("DEAL_TYPE", col).toLowerCase()];
    }
  };
  const deal_types = () => {
    return Object.keys(deal_specs).map((x) => {
      return x.toUpperCase();
    });
  };
  const deal_types_single_leg = () => {
    let single_leg_deals = [];
    for (const [key, value] of Object.entries(deal_specs)) {
      if (value.legs === 1) {
        single_leg_deals.push(key.toUpperCase());
      }
    }
    return single_leg_deals;
  };
  const get_deal_specs_by_leg_type = (col) => {
    return deal_specs[keyVal_input_col("LEG_TYPE", col).toLowerCase()];
  };
  const set_custom_deal_type = () => {
    for (let c = 1; c < jRef.current.jspreadsheet.headers.length; c++) {
      setCellValue("CUSTOM", "DEAL_TYPE", c);
    }
    setIs_custom_deal(true);
  };
  const unhide_row = (key) => {
    jRef.current.jspreadsheet.rows[jtable_keys.indexOf(key)].hidden = false;
  };
  const hide_row = (key) => {
    jRef.current.jspreadsheet.rows[jtable_keys.indexOf(key)].hidden = true;
  };
  const unhide_all_keys = () => {
    for (const key of jtable_keys) {
      unhide_row(key);
    }
  };
  const refreshRfqObjectData = () => {
    if (rfq_object !== undefined) {
      if (rfq_object.rfq_table_data !== null) {
        for (
          let i = 1;
          i <= Object.keys(rfq_object.rfq_table_data[0]).length - 1;
          i++
        ) {
          for (let rfqData of rfq_object.rfq_table_data) {
            if (rfqMode === "rfq booking view") {
              if (rfqData[0] === "BARRIER" && rfqData[1][0] !== "") {
                dispatch(rfqSlice.removeHidenKey("BARRIER"));
                setCellValue(`${rfqData[i]}`, rfqData[0], i);
              }
              if (rfqData[0] === "BARRIER2" && rfqData[1][0] !== "") {
                dispatch(rfqSlice.removeHidenKey("BARRIER"));
                dispatch(rfqSlice.removeHidenKey("BARRIER2"));
                setCellValue(`${rfqData[i]}`, rfqData[0], i);
              }
              if (rfqData[0] === "BBG_MID VOL") {
                dispatch(rfqSlice.addHidenKey("BBG_MID_VOL"));
              }

              if (rfqData[0] === "HEDGE_MATCH_TYPE") {
                if (rfqData[1][0] === "UNMATCHED") {
                  dispatch(rfqSlice.addHidenKey("HEDGE_TYPE"));
                  dispatch(rfqSlice.addHidenKey("HEDGE_TYPE"));
                  dispatch(rfqSlice.addHidenKey("HEDGE_VAL"));
                  dispatch(rfqSlice.addHidenKey("HEDGE_RATE"));
                  dispatch(rfqSlice.addHidenKey("HEDGE_DELTA"));

                  dispatch(rfqSlice.removeHidenKey("CLIENT_HEDGE_TYPE"));
                  dispatch(rfqSlice.removeHidenKey("CLIENT_HEDGE_VAL"));
                  dispatch(rfqSlice.removeHidenKey("CLIENT_HEDGE_RATE"));
                  dispatch(rfqSlice.removeHidenKey("CLIENT_HEDGE_DELTA"));

                  dispatch(rfqSlice.removeHidenKey("MM_HEDGE_TYPE"));
                  dispatch(rfqSlice.removeHidenKey("MM_HEDGE_VAL"));
                  dispatch(rfqSlice.removeHidenKey("MM_HEDGE_RATE"));
                  dispatch(rfqSlice.removeHidenKey("MM_HEDGE_DELTA"));
                }
                if (rfqData[1][0] === "MATCHED") {
                  dispatch(rfqSlice.addHidenKey("MM_HEDGE_TYPE"));
                  dispatch(rfqSlice.addHidenKey("MM_HEDGE_VAL"));
                  dispatch(rfqSlice.addHidenKey("MM_HEDGE_RATE"));
                  dispatch(rfqSlice.addHidenKey("MM_HEDGE_DELTA"));
                  dispatch(rfqSlice.addHidenKey("CLIENT_HEDGE_TYPE"));
                  dispatch(rfqSlice.addHidenKey("CLIENT_HEDGE_VAL"));
                  dispatch(rfqSlice.addHidenKey("CLIENT_HEDGE_RATE"));
                  dispatch(rfqSlice.addHidenKey("CLIENT_HEDGE_DELTA"));
                  dispatch(rfqSlice.removeHidenKey("HEDGE_TYPE"));
                  dispatch(rfqSlice.removeHidenKey("HEDGE_VAL"));
                  dispatch(rfqSlice.removeHidenKey("HEDGE_RATE"));
                  dispatch(rfqSlice.removeHidenKey("HEDGE_DELTA"));
                }
                // dispatch(rfqSlice.removeHidenKey("HEDGE_TYPE"));
                // dispatch(rfqSlice.removeHidenKey("HEDGE_VAL"));
                // dispatch(rfqSlice.removeHidenKey("HEDGE_RATE"));
                // dispatch(rfqSlice.removeHidenKey("HEDGE_DELTA"));
              }
              if (
                rfqData[1][0] === "DNT" ||
                rfqData[1][0] === "OT" ||
                rfqData[1][0] === "NT"
              ) {
                dispatch(rfqSlice.addHidenKey("CALL_PUT"));
                dispatch(rfqSlice.addHidenKey("STRIKE"));
              }
              if (rfqData[0] === "NOTIONAL" && rfqData[1][0] !== "") {
                setCellValue(rfqData[1], rfqData[0], i);
              }
              if (rfqData[0] === "STRIKE" && rfqData[1][0] !== "") {
                setCellValue((+rfqData[i]).toFixed(2), rfqData[0], i);
              }
              if (Array.isArray(rfqData[i])) {
                setCellValue(rfqData[i][0], rfqData[0], i);
              } else if (!Array.isArray(rfqData[i])) {
                setCellValue(rfqData[i], rfqData[0], i);
              }
            }
          }
          if (i !== Object.keys(rfq_object.rfq_table_data[0]).length - 1) {
            dispatch(rfqSlice.removeHidenKey("NET_MARKET_PRICE"));
            dispatch(rfqSlice.removeHidenKey("NET_CLIENT_PRICE"));
            regionFormat.remove_format_underline(
              "MARKET_PREMIUM",
              jRef,
              cellId,
              keyRow,
            );
            addNewColumn();
          }
        }
      }
    }
  };
  const addNewColumn = () => {
    jRef.current.jspreadsheet.options.allowInsertColumn = true;
    jRef.current.jspreadsheet.insertColumn();
    setSecond_last_column((p) => p++);
    jRef.current.jspreadsheet.options.allowInsertColumn = false;
    let col_num = jRef.current.jspreadsheet.headers.length - 1;
    jRef.current.jspreadsheet.headers[col_num].innerText = `Leg ${col_num}`;
    jRef.current.jspreadsheet.colgroup[col_num].attributes[0].value = 175;
    format_table();
  };
  const HandleRfqBlotterChecked = async () => {
    rfq_object = {
      ...rfq_object,
      rfq_id: rfq.length > 0 ? rfq[0].rfq_id : sessionStorage.getItem("rfqId"),
    };
    handle_visible_rows();
    let rfqMode = sessionStorage.getItem("rfqMode");
    if (rfqBookingModeUpdatingRows.length === 0) {
      if (rfq.length > 0) {
        for (let index = 0; index < Number(rfq.length) - 1; index++) {
          addNewColumn();
        }
        handleRfqDataTable(rfq);
      }
      if (rfq.length === 0) {
        refreshRfqObjectData();
        handleReadOnlyClass(
          jRef.current.jspreadsheet.headers,
          jRef.current.jspreadsheet.rows,
        );
      }
    } else if (rfqBookingModeUpdatingRows.length > 0) {
      if (rfq.length > 0) {
        for (let index = 0; index < Number(rfq.length) - 1; index++) {
          addNewColumn();
        }
        if (rfqMode === "" || rfqMode === "rfq view" || rfqMode === null) {
          handleRfqDataTable(rfq);
        } else if (rfqMode === "rfq booking view") {
          handleRfqDataTable(rfqBookingModeUpdatingRows);
        }
      } else if (rfq.length === 0) {
      }
    }
    format_table();
  };
  const handleRfqDataTable = (rfq) => {
    let status = sessionStorage.getItem("status");
    let rfqMode = sessionStorage.getItem("rfqMode");

    if (status === "open" || status === "" || status === "newRfq") {
      setLaunchStatus(true);
      handleRfqWorkSpaceTableData(
        rfq,
        addNewColumn,
        setCellValue,
        rfqSlice.removeHidenKey,
        rfqSlice.addHidenKey,
        dispatch,
        jRef,
        assign_jexcel_data,
        rfqMode,
        rfq.length,
        getCcypairGroupRfqChecked,
        handleReadOnlyClass,
        rfqBookingModeUpdatingRows,
      );
      assign_jexcel_data();
    }
    if (status === "contribute" || rfqMode === "rfq aggregator view") {
      setLaunchStatus(true);
      handleRfqWorkSpaceTableData(
        rfq,
        addNewColumn,
        setCellValue,
        rfqSlice.removeHidenKey,
        rfqSlice.addHidenKey,
        dispatch,
        jRef,
        assign_jexcel_data,
        rfqMode,
        rfq.length,
        getCcypairGroupRfqChecked,
        handleReadOnlyClass,
        rfqBookingModeUpdatingRows,
      );
      assign_jexcel_data();
      setButtonBackgrounds({
        rfqBokingBc: "#171717",
        rfqViewBc: "#171717",
        aggregatorBc: "#FF681C",
      });
      setLaunchStatus(true);
    }
    if (rfqMode === "rfq booking view") {
      setLaunchStatus(true);
      handleRfqWorkSpaceTableData(
        rfq,
        addNewColumn,
        setCellValue,
        rfqSlice.removeHidenKey,
        rfqSlice.addHidenKey,
        dispatch,
        jRef,
        assign_jexcel_data,
        rfqMode,
        rfq.length,
        getCcypairGroupRfqChecked,
        handleReadOnlyClass,
        rfqBookingModeUpdatingRows,
      );
      assign_jexcel_data();
      if (jRef.current.jspreadsheet.getJson()[0][1][0] !== "") {
        setButtonBackgrounds({
          rfqBokingBc: "#FF681C",
          rfqViewBc: "#171717",
          aggregatorBc: "#171717",
        });
        setLaunchStatus(true);
      }
    }
    if (status === "copy") {
      setLaunchStatus(false);
      dispatch(rfqSlice.clearCreatedRfqDetails());
      handleCopyRows();
    }
  };
  const handleReadOnlyClass = (headers, row, rfqList = null) => {
    const traderId = sessionStorage.getItem("traderId");
    const rfqMode = sessionStorage.getItem("rfqMode");
    let rfqStatus = sessionStorage.getItem("rfqStatus");
    const rfqTraderId = sessionStorage.getItem("rfqTraderId");
    const status = sessionStorage.getItem("status");
    const rfqDealType = sessionStorage.getItem("rfqDealType");
    if (rfqList !== null) {
      const filter = rfqList.filter(
        (rfq) => Number(rfq.rfq_id) === Number(sessionStorage.getItem("rfqId")),
      );
      if (filter.length > 0) {
        rfqStatus = filter[0].status;
        sessionStorage.setItem("rfqStatus", filter[0].status);
      }
    }
    if (rfqMode === "rfq view" || rfqMode === "" || rfqMode === null) {
      if (status === "copy" || status === "newRfq") {
        let leg;
        for (let col = 0; col < headers.length; col++) {
          for (let index = 0; index < row.length - 1; index++) {
            const cell = getCell(col, index);
            let id = jspreadsheet.getColumnNameFromId([col, index]);
            switch (col) {
              case 1:
                leg = "B";
                break;
              case 2:
                leg = "C";
                break;
              case 3:
                leg = "D";
                break;
              case 4:
                leg = "E";
                break;
              case 5:
                leg = "F";
                break;
              case 6:
                leg = "G";
                break;
              case 7:
                leg = "H";
                break;
              case 8:
                leg = "I";
                break;
              case 9:
                leg = "J";
                break;
              case 10:
                leg = "K";
                break;
              case 0:
                leg = "A";
                break;
              default:
                break;
            }
            if (rfqDealType !== "SWAP") {
              if (
                leg === "A" ||
                id === `${leg}12` ||
                id === `${leg}21` ||
                id === `${leg}22` ||
                id === `${leg}23` ||
                id === `${leg}24` ||
                id === `${leg}25` ||
                id === `${leg}26` ||
                id === `${leg}27` ||
                id === `${leg}28` ||
                id === `${leg}29` ||
                id === `${leg}31` ||
                id === `${leg}32` ||
                id === `${leg}43` ||
                id === `${leg}44`
              ) {
                cell.classList.add("readonly");
              } else {
                cell.classList.remove("readonly");
              }
            } else if (rfqDealType === "SWAP") {
              if (
                leg === "A" ||
                id === `${leg}18` ||
                id === `${leg}21` ||
                id === `${leg}27` ||
                id === `${leg}28`
              ) {
                cell.classList.add("readonly");
              } else {
                cell.classList.remove("readonly");
              }
            }
          }
        }
      } else if (status === "" || status === "open") {
        if (
          rfqStatus === "Booked" ||
          rfqStatus === "Confirmed & Booked" ||
          rfqStatus === "Confirmed" ||
          (rfqTraderId !== "" && Number(rfqTraderId) !== Number(traderId)) ||
          tableData.length > 0
        ) {
          for (let col = 0; col < headers.length; col++) {
            for (let index = 0; index < row.length - 1; index++) {
              const cell = getCell(col, index);
              cell.classList.add("readonly");
            }
          }
        } else {
          let leg;
          for (let col = 0; col < headers.length; col++) {
            for (let index = 0; index < row.length - 1; index++) {
              const cell = getCell(col, index);
              let id = jspreadsheet.getColumnNameFromId([col, index]);
              switch (col) {
                case 1:
                  leg = "B";
                  break;
                case 2:
                  leg = "C";
                  break;
                case 3:
                  leg = "D";
                  break;
                case 4:
                  leg = "E";
                  break;
                case 5:
                  leg = "F";
                  break;
                case 6:
                  leg = "G";
                  break;
                case 7:
                  leg = "H";
                  break;
                case 8:
                  leg = "I";
                  break;
                case 9:
                  leg = "J";
                  break;
                case 10:
                  leg = "K";
                  break;
                case 0:
                  leg = "A";
                  break;
                default:
                  break;
              }
              if (rfqDealType !== "SWAP") {
                if (
                  leg === "A" ||
                  id === `${leg}12` ||
                  id === `${leg}21` ||
                  id === `${leg}22` ||
                  id === `${leg}23` ||
                  id === `${leg}24` ||
                  id === `${leg}25` ||
                  id === `${leg}26` ||
                  id === `${leg}27` ||
                  id === `${leg}28` ||
                  id === `${leg}29` ||
                  id === `${leg}31` ||
                  id === `${leg}32` ||
                  id === `${leg}43` ||
                  id === `${leg}44`
                ) {
                  cell.classList.add("readonly");
                } else {
                  cell.classList.remove("readonly");
                }
              } else if (rfqDealType === "SWAP") {
                if (
                  leg === "A" ||
                  id === `${leg}18` ||
                  id === `${leg}21` ||
                  id === `${leg}27` ||
                  id === `${leg}28`
                ) {
                  cell.classList.add("readonly");
                } else {
                  cell.classList.remove("readonly");
                }
              }
            }
          }
        }
      }
    } else if (rfqMode === "rfq booking view") {
      if (rfqStatus === "Booked" || rfqStatus === "Confirmed & Booked") {
        for (let col = 0; col < headers.length; col++) {
          for (let index = 0; index < row.length - 1; index++) {
            const cell = getCell(col, index);
            cell.classList.add("readonly");
          }
        }
      } else if (rfqMode === "rfq booking view") {
        let leg;
        for (let col = 0; col < headers.length; col++) {
          for (let index = 0; index < row.length - 1; index++) {
            const cell = getCell(col, index);
            let id = jspreadsheet.getColumnNameFromId([col, index]);

            switch (col) {
              case 1:
                leg = "B";
                break;
              case 2:
                leg = "C";
                break;
              case 3:
                leg = "D";
                break;
              case 4:
                leg = "E";
                break;
              case 5:
                leg = "F";
                break;
              case 6:
                leg = "G";
                break;
              case 7:
                leg = "H";
                break;
              case 8:
                leg = "I";
                break;
              case 9:
                leg = "J";
                break;
              case 10:
                leg = "K";
                break;
              case 0:
                leg = "A";
                break;
              default:
                break;
            }

            if (rfqDealType !== "SWAP") {
              if (
                leg === "A" ||
                id === `${leg}2` ||
                id === `${leg}3` ||
                id === `${leg}4` ||
                id === `${leg}12` ||
                id === `${leg}22` ||
                id === `${leg}23` ||
                id === `${leg}24` ||
                id === `${leg}25` ||
                id === `${leg}26` ||
                id === `${leg}27` ||
                id === `${leg}28` ||
                id === `${leg}32` ||
                id === `${leg}43` ||
                id === `${leg}44` ||
                id === `${leg}45` ||
                id === `${leg}46` ||
                id === `${leg}47` ||
                id === `${leg}48` ||
                id === `${leg}49` ||
                id === `${leg}53` ||
                id === `${leg}54` ||
                id === `${leg}56` ||
                id === `${leg}57` ||
                id === `${leg}64` ||
                id === `${leg}65` ||
                id === `${leg}69` ||
                id === `${leg}73` ||
                id === `${leg}76`
              ) {
                cell.classList.add("readonly");
              } else {
                cell.classList.remove("readonly");
              }
            } else if (rfqDealType === "SWAP") {
              if (
                leg === "A" ||
                id === `${leg}2` ||
                id === `${leg}3` ||
                id === `${leg}18` ||
                id === `${leg}128` ||
                id === `${leg}57` ||
                id === `${leg}62` ||
                id === `${leg}63`
              ) {
                cell.classList.add("readonly");
              } else {
                cell.classList.remove("readonly");
              }
            }
          }
        }
      }
    }
  };

  const set_is_custom_deal_bool = () => {
    if (keyVal_input_col("DEAL_TYPE", 1) === "CUSTOM") {
      setIs_custom_deal(true);
    } else {
      setIs_custom_deal(false);
    }
  };
  const handle_visible_rows = () => {
    if (rfq.length > 0) {
      if (
        status === "copy" &&
        rfq[0].Strategy !== "wki" &&
        rfq[0].Strategy !== "wko" &&
        rfq[0].Strategy !== "wdki" &&
        rfq[0].Strategy !== "wdko"
      ) {
        dispatch(rfqSlice.addHidenKey("BARRIER_START_DATE"));
        dispatch(rfqSlice.addHidenKey("BARRIER_END_DATE"));
      } else {
        dispatch(rfqSlice.removeHidenKey("BARRIER_START_DATE"));
        dispatch(rfqSlice.removeHidenKey("BARRIER_END_DATE"));
      }
    }

    // swap_keys.forEach((key) => {
    //   dispatch(rfqSlice.addHidenKey(key));
    // });

    dispatch(rfqSlice.addHidenKey("HEDGE_TYPE"));
    dispatch(rfqSlice.addHidenKey("HEDGE_VAL"));
    dispatch(rfqSlice.addHidenKey("HEDGE_RATE"));
    dispatch(rfqSlice.addHidenKey("MM_HEDGE_TYPE"));
    dispatch(rfqSlice.addHidenKey("MM_HEDGE_VAL"));
    dispatch(rfqSlice.addHidenKey("MM_HEDGE_RATE"));
    dispatch(rfqSlice.addHidenKey("MM_HEDGE_DELTA"));
    dispatch(rfqSlice.addHidenKey("CLIENT_HEDGE_TYPE"));
    dispatch(rfqSlice.addHidenKey("CLIENT_HEDGE_RATE"));
    dispatch(rfqSlice.addHidenKey("CLIENT_HEDGE_VAL"));
    dispatch(rfqSlice.addHidenKey("CLIENT_HEDGE_DELTA"));
    dispatch(rfqSlice.addHidenKey("THIRD_CCY"));
    dispatch(rfqSlice.addHidenKey("FX_RATE"));
    jspreadsheet.hidden_keys = hidden_keys;
    handle_booking_mode();
    const is_vanillas_only = is_vanilla_only();
    const rfqDealType = sessionStorage.getItem("rfqDealType");
    if (rfqDealType === "SWAP") {
      handle_swap_rows();
    } else {
      for (const key of rfq_view_not_swp_hidden_rows) {
        dispatch(rfqSlice.addHidenKey(key));
      }
      if (is_vanillas_only) {
        dispatch(rfqSlice.removeHidenKey("STRIKE"));
        dispatch(rfqSlice.removeHidenKey("CALL_PUT"));
        jspreadsheet.hidden_keys = hidden_keys;
        handle_vanilla_rows();
      } else {
        handle_exotic_rows();
      }
    }
    handle_ndf_fixing_ref();
  };
  const handle_swap_rows = () => {
    for (const key of swap_keys) {
      dispatch(rfqSlice.addHidenKey(key));
    }
    const visible_swap_keys = [
      "REQUEST_FOR",
      "CCY_PAIR",
      "DEAL_TYPE",
      "SPOT_REF",
      "PB_ACCOUNT",
      "INCLUDE_REF",
      "NEAR_SWAP",
      "FAR_SWAP",
      "FIXING_REF",
      "NEAR_DATE_TEXT",
      "FAR_DATE_TEXT",
      "NEAR_NOTIONAL",
      "FAR_NOTIONAL",
      "SWAP_CCY",
      "NEAR_DATE",
      "FAR_DATE",
      "DATES_IN_RFQ",
      "CCYPAIR_GROUP",
    ];
    if (rfqMode === "rfq view" || rfqMode === "") {
      for (const key of visible_swap_keys) {
        dispatch(rfqSlice.removeHidenKey(key));
      }
    } else if (rfqMode === "rfq booking view") {
    }
    if (keyVal_input_col("CCYPAIR_GROUP", 1) !== "") {
      if (keyVal_input_col("CCYPAIR_GROUP", 1) === "NDF") {
        dispatch(rfqSlice.removeHidenKey("FAR_FIXING_DATE"));
        dispatch(rfqSlice.removeHidenKey("NEAR_FIXING_DATE"));
        dispatch(rfqSlice.removeHidenKey("CCYPAIR_GROUP"));
      } else {
        dispatch(rfqSlice.addHidenKey("FAR_FIXING_DATE"));
        dispatch(rfqSlice.addHidenKey("NEAR_FIXING_DATE"));
        dispatch(rfqSlice.addHidenKey("CCYPAIR_GROUP"));
      }
    } else if (keyVal_input_col("CCYPAIR_GROUP", 1) === "") {
      if (rfq.length > 0) {
        let ccypair_group = getCcypairGroupRfqChecked(rfq[0].ccy_pair);
        if (ccypair_group === "NDF") {
          dispatch(rfqSlice.removeHidenKey("FAR_FIXING_DATE"));
          dispatch(rfqSlice.removeHidenKey("NEAR_FIXING_DATE"));
          dispatch(rfqSlice.removeHidenKey("CCYPAIR_GROUP"));
        } else {
          dispatch(rfqSlice.addHidenKey("FAR_FIXING_DATE"));
          dispatch(rfqSlice.addHidenKey("NEAR_FIXING_DATE"));
          dispatch(rfqSlice.addHidenKey("CCYPAIR_GROUP"));
        }
      }
    }
  };

  const handle_vanilla_rows = () => {
    dispatch(rfqSlice.addHidenKey("BARRIER"));
    dispatch(rfqSlice.addHidenKey("BARRIER2"));
    dispatch(rfqSlice.removeHidenKey("LEG_TYPE"));
    dispatch(rfqSlice.removeHidenKey("EXPIRY_TEXT"));
    dispatch(rfqSlice.removeHidenKey("NOTIONAL"));
    dispatch(rfqSlice.removeHidenKey("BUY_SELL"));
    dispatch(rfqSlice.removeHidenKey("EXPIRY"));
    dispatch(rfqSlice.removeHidenKey("DELIVERY"));
    dispatch(rfqSlice.removeHidenKey("SPOT_DATE"));

    if (status !== "copy") {
      dispatch(rfqSlice.addHidenKey("BARRIER_START_DATE"));
      dispatch(rfqSlice.addHidenKey("BARRIER_END_DATE"));
    }
    dispatch(rfqSlice.addHidenKey("FIXING_REF"));
    dispatch(rfqSlice.addHidenKey("CCYPAIR_GROUP"));
    dispatch(rfqSlice.addHidenKey("OPTION_TYPE"));
    jspreadsheet.hidden_keys = hidden_keys;
  };
  const handle_exotic_rows = () => {
    dispatch(rfqSlice.addHidenKey("OPTION_TYPE"));
    dispatch(rfqSlice.addHidenKey("BARRIER"));
    dispatch(rfqSlice.addHidenKey("BARRIER2"));
    dispatch(rfqSlice.addHidenKey("VEGA"));
    dispatch(rfqSlice.addHidenKey("PRICE_PCT_NOTIONAL"));
    dispatch(rfqSlice.addHidenKey("HEDGE_AMOUNT"));
    dispatch(rfqSlice.addHidenKey("DELTA"));
    dispatch(rfqSlice.addHidenKey("BBG_MID_VOL"));
    if (sessionStorage.getItem("rfqMode") !== "rfq booking view") {
      dispatch(rfqSlice.addHidenKey("HEDGE_DELTA"));
    }
    jspreadsheet.hidden_keys = hidden_keys;
    let show_fixing_ref = false;
    let number_of_barriers = 0;
    let is_touch_options = false;
    let is_window_option = false;

    for (
      let index = 1;
      index < jRef.current.jspreadsheet.headers.length;
      index++
    ) {
      let specs = get_deal_specs_by_leg_type(index);
      if (
        specs.leg_type[0] !== "WKI" &&
        specs.leg_type[0] !== "WKO" &&
        specs.leg_type[0] !== "WDKI" &&
        specs.leg_type[0] !== "WDKO" &&
        specs.leg_type[0] !== "DIGITAL_WKI" &&
        specs.leg_type[0] !== "DIGITAL_WKO" &&
        specs.leg_type[0] !== "DIGITAL_WDKI" &&
        specs.leg_type[0] !== "DIGITAL_WDKO"
      ) {
        setCellValue("", "BARRIER_START_DATE", index);
        setCellValue("", "BARRIER_END_DATE", index);
      }
      if (specs?.needs_fixing_ref) {
        show_fixing_ref = true;
      }
      if (specs?.number_of_barriers > number_of_barriers) {
        number_of_barriers = specs?.number_of_barriers;
      }
      if (["DNT", "OT", "NT"].includes(specs.leg_type[0])) {
        is_touch_options = true;
      }
      if (
        [
          "WKI",
          "WKO",
          "WDKO",
          "WDKI",
          "DIGITAL_WKI",
          "DIGITAL_WKO",
          "DIGITAL_WDKI",
          "DIGITAL_WDKO",
        ].includes(specs?.leg_type[0])
      ) {
        is_window_option = true;
      }
    }
    if (show_fixing_ref) {
      dispatch(rfqSlice.removeHidenKey("FIXING_REF"));
    }
    if (is_window_option) {
      dispatch(rfqSlice.removeHidenKey("BARRIER_START_DATE"));
      dispatch(rfqSlice.removeHidenKey("BARRIER_END_DATE"));
    }
    if (!is_window_option) {
      dispatch(rfqSlice.addHidenKey("BARRIER_START_DATE"));
      dispatch(rfqSlice.addHidenKey("BARRIER_END_DATE"));
    }
    if (number_of_barriers === 0) {
      dispatch(rfqSlice.addHidenKey("BARRIER2"));
      dispatch(rfqSlice.addHidenKey("BARRIER"));
    }
    if (number_of_barriers === 1) {
      dispatch(rfqSlice.removeHidenKey("BARRIER"));
    }
    if (number_of_barriers === 2) {
      dispatch(rfqSlice.removeHidenKey("BARRIER"));
      dispatch(rfqSlice.removeHidenKey("BARRIER2"));
    }
    if (is_touch_options) {
      if (are_leg_types_the_same()) {
        dispatch(rfqSlice.addHidenKey("STRIKE"));
        dispatch(rfqSlice.addHidenKey("CALL_PUT"));
      }
    }
  };
  const handle_booking_mode = () => {
    if (rfqMode === "rfq booking view") {
      enter_booking_mode();
    } else {
      exit_booking_mode();
    }
  };
  const enter_booking_mode = () => {
    dispatch(rfqSlice.addHidenKey("FIXING_REF"));
    add_booking_mode_classlist();
    booking_mode_hidden_rows.forEach((row) => {
      dispatch(rfqSlice.addHidenKey(row));
    });
    if (rfq.length > 0) {
      if (rfqBookingModeUpdatingRows.length === 0) {
        if (rfq[0].hadge_match_type === "MATCHED") {
          dispatch(rfqSlice.removeHidenKey("HEDGE_TYPE"));
          dispatch(rfqSlice.removeHidenKey("HEDGE_VAL"));
          dispatch(rfqSlice.removeHidenKey("HEDGE_RATE"));
          dispatch(rfqSlice.removeHidenKey("HEDGE_DELTA"));

          dispatch(rfqSlice.addHidenKey("CLIENT_HEDGE_TYPE"));
          dispatch(rfqSlice.addHidenKey("CLIENT_HEDGE_RATE"));
          dispatch(rfqSlice.addHidenKey("CLIENT_HEDGE_VAL"));
          dispatch(rfqSlice.addHidenKey("CLIENT_HEDGE_DELTA"));
        } else if (rfq[0].hadge_match_type === "UNMATCHED") {
          dispatch(rfqSlice.addHidenKey("HEDGE_TYPE"));
          dispatch(rfqSlice.addHidenKey("HEDGE_VAL"));
          dispatch(rfqSlice.addHidenKey("HEDGE_RATE"));
          dispatch(rfqSlice.addHidenKey("HEDGE_DELTA"));

          dispatch(rfqSlice.removeHidenKey("MM_HEDGE_TYPE"));
          dispatch(rfqSlice.removeHidenKey("MM_HEDGE_VAL"));
          dispatch(rfqSlice.removeHidenKey("MM_HEDGE_RATE"));
          dispatch(rfqSlice.removeHidenKey("MM_HEDGE_DELTA"));

          dispatch(rfqSlice.removeHidenKey("CLIENT_HEDGE_TYPE"));
          dispatch(rfqSlice.removeHidenKey("CLIENT_HEDGE_VAL"));
          dispatch(rfqSlice.removeHidenKey("CLIENT_HEDGE_RATE"));
          dispatch(rfqSlice.removeHidenKey("CLIENT_HEDGE_DELTA"));
        }
      } else if (rfqBookingModeUpdatingRows.length > 0) {
        if (
          rfqBookingModeUpdatingRows[0].hadge_match_type === "MATCHED" ||
          rfqBookingModeUpdatingRows[0].hedge_match_type === "MATCHED"
        ) {
          dispatch(rfqSlice.removeHidenKey("HEDGE_TYPE"));
          dispatch(rfqSlice.removeHidenKey("HEDGE_VAL"));
          dispatch(rfqSlice.removeHidenKey("HEDGE_RATE"));
          dispatch(rfqSlice.removeHidenKey("HEDGE_DELTA"));
          dispatch(rfqSlice.addHidenKey("MM_HEDGE_TYPE"));
          dispatch(rfqSlice.addHidenKey("MM_HEDGE_VAL"));
          dispatch(rfqSlice.addHidenKey("MM_HEDGE_RATE"));
          dispatch(rfqSlice.addHidenKey("MM_HEDGE_DELTA"));
          dispatch(rfqSlice.addHidenKey("CLIENT_HEDGE_TYPE"));
          dispatch(rfqSlice.addHidenKey("CLIENT_HEDGE_RATE"));
          dispatch(rfqSlice.addHidenKey("CLIENT_HEDGE_VAL"));
          dispatch(rfqSlice.addHidenKey("CLIENT_HEDGE_DELTA"));
        } else if (
          rfqBookingModeUpdatingRows[0].hadge_match_type === "UNMATCHED" ||
          rfqBookingModeUpdatingRows[0].hedge_match_type === "UNMATCHED"
        ) {
          dispatch(rfqSlice.addHidenKey("HEDGE_TYPE"));
          dispatch(rfqSlice.addHidenKey("HEDGE_VAL"));
          dispatch(rfqSlice.addHidenKey("HEDGE_RATE"));
          dispatch(rfqSlice.addHidenKey("HEDGE_DELTA"));

          dispatch(rfqSlice.removeHidenKey("MM_HEDGE_TYPE"));
          dispatch(rfqSlice.removeHidenKey("MM_HEDGE_VAL"));
          dispatch(rfqSlice.removeHidenKey("MM_HEDGE_RATE"));
          dispatch(rfqSlice.removeHidenKey("MM_HEDGE_DELTA"));

          dispatch(rfqSlice.removeHidenKey("CLIENT_HEDGE_TYPE"));
          dispatch(rfqSlice.removeHidenKey("CLIENT_HEDGE_VAL"));
          dispatch(rfqSlice.removeHidenKey("CLIENT_HEDGE_RATE"));
          dispatch(rfqSlice.removeHidenKey("CLIENT_HEDGE_DELTA"));
        }
      }
    } else if (jRef.current.jspreadsheet.getJson()[0][1][39] !== "") {
      dispatch(rfqSlice.removeHidenKey("HEDGE_TYPE"));
      dispatch(rfqSlice.removeHidenKey("HEDGE_VAL"));
      dispatch(rfqSlice.removeHidenKey("HEDGE_RATE"));
      dispatch(rfqSlice.removeHidenKey("HEDGE_DELTA"));
    }
    dispatch(rfqSlice.addHidenKey("BBG_MID_VOL"));
    dispatch(rfqSlice.addHidenKey("DELTA"));
    dispatch(rfqSlice.removeHidenKey("NOTIONAL_CCY"));
    dispatch(rfqSlice.removeHidenKey("PREMIUM_VAL"));
    dispatch(rfqSlice.removeHidenKey("PREMIUM_TYPE"));
    dispatch(rfqSlice.removeHidenKey("PREMIUM_CCY"));
    dispatch(rfqSlice.removeHidenKey("CLIENT_PRICE"));
    dispatch(rfqSlice.removeHidenKey("MARKET_PRICE"));
    dispatch(rfqSlice.removeHidenKey("CLIENT_PREMIUM"));
    dispatch(rfqSlice.removeHidenKey("MARKET_PREMIUM"));
    dispatch(rfqSlice.removeHidenKey("HEDGE_MATCH_TYPE"));
  };
  const exit_booking_mode = () => {
    post_trade_rows.forEach((row) => {
      dispatch(rfqSlice.addHidenKey(row));
    });
    booking_mode_hidden_rows.forEach((row) => {
      dispatch(rfqSlice.removeHidenKey(row));
    });
    dispatch(rfqSlice.addHidenKey("HEDGE_MATCH_TYPE"));

    if (jRef.current.jspreadsheet.headers.length > 2 && is_vanilla_only()) {
      dispatch(rfqSlice.removeHidenKey("NET_MARKET_PRICE"));
    }
  };
  const add_booking_mode_classlist = () => {
    for (const key of booking_rows_attention) {
      let row = keyRow(key);
      let cell = getCell(0, row);

      cell.classList.add("booking_mode");
    }
  };

  const is_touch_options = () => {};
  const set_hidden_keys = () => {
    if (hidden_keys) {
      jspreadsheet.hidden_keys = hidden_keys;

      for (const row of hidden_keys) {
        hide_row(row);
      }
    }
  };
  const handle_ndf_fixing_ref = () => {
    if (rfqMode !== "rfq booking view") {
      // dispatch(rfqSlice.addHidenKey("CCYPAIR_GROUP"));
      let ccypair_group = keyVal_input_col("CCYPAIR_GROUP", 1);
      if (ccypair_group === "NDF") {
        dispatch(rfqSlice.removeHidenKey("CCYPAIR_GROUP"));
        dispatch(rfqSlice.removeHidenKey("FIXING_REF"));
      }
    }
  };
  const are_leg_types_the_same = () => {
    let option_types = [];
    for (
      let index = 1;
      index < jRef.current.jspreadsheet.headers.length;
      index++
    ) {
      option_types.push(keyVal_input_col("LEG_TYPE", index));
    }
    const allEqual = (arr) => arr.every((v) => v === arr[0]);

    return allEqual(option_types);
  };
  const get_ccypair_group = (col) => {
    let ccy_pair = keyVal_input_col("CCY_PAIR", col).toUpperCase();
    let base_ccy = ccy_pair.substring(0, 3);
    let terms_ccy = ccy_pair.substring(3, 6);

    if (NDF_CCY.includes(base_ccy) || NDF_CCY.includes(terms_ccy)) {
      return "NDF";
    }
    if (G10_CCY.includes(base_ccy) && G10_CCY.includes(terms_ccy)) {
      return "G10";
    }
    return "NONE";
  };
  const getCcypairGroupRfqChecked = () => {
    if (rfq.length > 0) {
      let ccy_pair = rfq[0].ccy_pair.toUpperCase();
      let base_ccy = ccy_pair.substring(0, 3);
      let terms_ccy = ccy_pair.substring(3, 6);

      if (NDF_CCY.includes(base_ccy) || NDF_CCY.includes(terms_ccy)) {
        return "NDF";
      }
      if (G10_CCY.includes(base_ccy) && G10_CCY.includes(terms_ccy)) {
        return "G10";
      }
      return "NONE";
    }
  };
  const clear_existing_grid = (exlude_key_list) => {
    for (
      let index = 1;
      index < jRef.current.jspreadsheet.headers.length;
      index++
    ) {
      for (const key of jtable_keys) {
        if (!exlude_key_list.includes(key)) {
          setCellValue("", key, index);
        }
      }
    }
  };
  const keyVal_input_col = (key, col) => {
    const value = jRef.current.jspreadsheet.getValueFromCoords(
      col,
      keyRow(key),
    );
    if (Array.isArray(value)) {
      return value[0];
    }
    return value;
  };
  const update_expiry_text = async (col) => {
    if (keyVal_input_col("EXPIRY_TEXT", col) === "") {
      return;
    }

    let validate = await regionApiCalls.validate_expiry_text(
      keyVal_input_col("EXPIRY_TEXT", col),
      axios,
    );

    if (!validate) {
      dispatch(
        actionSnackBar.setSnackBar("error", "EXPIRY TEXT IS INVALID", 3000),
      );
      return;
    }
    set_expiry(col);
    await initialize_rfq(
      keyVal_input_col("CCY_PAIR", col),
      keyVal_input_col("EXPIRY_TEXT", col),
      col,
    );
  };
  const initialize_rfq = async (cross, expiry_text, col) => {
    if (cross === "" || expiry_text === "") {
      return;
    }
    try {
      let response = await axios.post(
        `${process.env.REACT_APP_PYTON}` + `initialize_rfq`,
        { cross: cross, expiry_text: expiry_text },
      );
      let spotRef = await axios.get(
        `${process.env.REACT_APP_BLOOMBERG}` + `spot/${cross}`,
      );
      let spotRefNumber = Number(spotRef.data.price);
      let fwdRef = await axios.get(
        `${process.env.REACT_APP_BLOOMBERG}` +
          `forwards/${cross}/${response.data.expiry}`,
      );
      let fwdRefNumber = Number(fwdRef.data.price);

      let results = response.data;

      if (keyVal_input_col("CCYPAIR_GROUP", col) === "G10") {
        document.addEventListener("keydown", add_cut_toggle_to_g10_ccy);
      }
      let expiry = keyVal_input_col("EXPIRY_TEXT", col);
      let rfqLegType = keyVal_input_col("LEG_TYPE", col);

      if (
        rfqLegType === "WKO" ||
        rfqLegType === "WKI" ||
        rfqLegType === "WDKO" ||
        rfqLegType === "WDKI" ||
        rfqLegType === "DIGITAL_WKO" ||
        rfqLegType === "DIGITAL_WKI" ||
        rfqLegType === "DIGITAL_WDKO" ||
        rfqLegType === "DIGITAL_WDKI"
      ) {
        setCellValue(results.expiry, "BARRIER_END_DATE", col);
        setCellValue(
          moment(new Date()).format("DD-MMM-YY"),
          "BARRIER_START_DATE",
          col,
        );
      }
      for (
        let index = 1;
        index < jRef.current.jspreadsheet.headers.length;
        index++
      ) {
        setCellValue(results.cut, "CUT", index);
        setCellValue(results.fixing_ref, "FIXING_REF", index);
        setCellValue(spotRefNumber.toFixed(4), "SPOT_REF", index);
        setCellValue(results.spot_date, "SPOT_DATE", index);

        if (
          keyVal_input_col("EXPIRY_TEXT", index) === expiry ||
          keyVal_input_col("EXPIRY_TEXT", index) === results.expiry_text
        ) {
          setCellValue(results.expiry_text, "EXPIRY_TEXT", index);
          setCellValue(fwdRefNumber.toFixed(4), "FWD_REF", index);
          setCellValue(results.expiry, "EXPIRY", index);
          setCellValue(results.delivery, "DELIVERY", index);
          if (sessionStorage.getItem("rfqMode") === "rfq booking view") {
            let rfqObjectToUpdate = {
              rfq_id:
                rfq.length > 0
                  ? rfq[0].rfq_id
                  : sessionStorage.getItem("rfqId"),
              rfqObjectToUpdate: jRef.current.jspreadsheet.getJson(),
            };

            await updateRfqBookingModeDetails(rfqObjectToUpdate);
          }
          //  assign_jexcel_data()
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleCopyRows = async () => {
    handle_visible_rows();
    if (rfqDealType !== "SWAP") {
      for (
        let index = 0;
        index < jRef.current.jspreadsheet.headers.length - 1;
        index++
      ) {
        setCellValue(rfq[index].ccy_pair, "CCY_PAIR", index + 1);
        setCellValue(rfq[index].ccypair_group, "CCYPAIR_GROUP", index + 1);
        setCellValue(rfq[index].expiry_text, "EXPIRY_TEXT", index + 1);
        setCellValue(rfq[index].leg_type_text, "DEAL_TYPE", index + 1);
        setCellValue(rfq[index].deal_type_text, "LEG_TYPE", index + 1);
        setCellValue(rfq[index].option_type_text, "OPTION_TYPE", index + 1);

        await initialize_rfq(
          rfq[index].ccy_pair,
          rfq[index].expiry_text,
          index + 1,
        );
        if (rfq[index].option_type_text === "EXOTIC") {
          dispatch(rfqSlice.addHidenKey("VEGA"));

          dispatch(rfqSlice.addHidenKey("PRICE_PCT_NOTIONAL"));

          dispatch(rfqSlice.addHidenKey("HEDGE_AMOUNT"));

          dispatch(rfqSlice.addHidenKey("DELTA"));

          dispatch(rfqSlice.addHidenKey("BBG_MID_VOL"));
        }

        if (sessionStorage.getItem("rfqMode") !== "rfq booking view") {
          dispatch(rfqSlice.addHidenKey("HEDGE_DELTA"));
        }
        if (rfq[index].strike === "") {
          dispatch(rfqSlice.addHidenKey("STRIKE"));
        } else {
          dispatch(rfqSlice.removeHidenKey("STRIKE"));
          setCellValue(rfq[index].strike, "STRIKE", index + 1);
        }
        if (rfq[index].barrier_1 === "") {
          dispatch(rfqSlice.addHidenKey("BARRIER"));
          dispatch(rfqSlice.addHidenKey("BARRIER2"));
        } else {
          dispatch(rfqSlice.removeHidenKey("BARRIER"));
          dispatch(rfqSlice.addHidenKey("BARRIER2"));
          setCellValue(rfq[index].barrier_1, "BARRIER", index + 1);
        }
        if (rfq[index].barrier_2 !== "") {
          dispatch(rfqSlice.removeHidenKey("BARRIER"));
          dispatch(rfqSlice.removeHidenKey("BARRIER2"));
          setCellValue(rfq[index].barrier_2, "BARRIER2", index + 1);
        }
        if (["DNT", "OT", "NT"].includes(rfq[index].deal_type_text)) {
          if (are_leg_types_the_same()) {
            dispatch(rfqSlice.addHidenKey("STRIKE"));
            dispatch(rfqSlice.addHidenKey("CALL_PUT"));
          }
        }
        setCellValue(rfq[index].call_put, "CALL_PUT", index + 1);
        setCellValue(rfq[index].notional, "NOTIONAL", index + 1);
        setCellValue(rfq[index].buy_sell, "BUY_SELL", index + 1);
        setCellValue(rfq[index].pb_account, "PB_ACCOUNT", index + 1);

        setCellValue(rfq[index].rfqFormat, "RFQ_FORMAT", index + 1);
        setCellValue(rfq[index].ref_type, "REF_TYPE", index + 1);
        setCellValue(rfq[index].includingRef, "INCLUDE_REF", index + 1);

        setCellValue(rfq[index].notional_ccy, "NOTIONAL_CCY", index + 1);
        setCellValue(rfq[index].hedge_type, "HEDGE_TYPE", index + 1);
        setCellValue(rfq[index].premium_type, "PREMIUM_TYPE", index + 1);
        setCellValue(rfq[index].premium_val, "PREMIUM_VAL", index + 1);
        setCellValue(rfq[index].premium_ccy, "PREMIUM_CCY", index + 1);
        setCellValue("FWD", "MM_HEDGE_TYPE", index + 1);
        setCellValue("FWD", "CLIENT_HEDGE_TYPE", index + 1);
      }
    } else {
      for (
        let index = 0;
        index < jRef.current.jspreadsheet.headers.length - 1;
        index++
      ) {
        setCellValue(rfq[index].ccy_pair, "CCY_PAIR", index + 1);
        setCellValue(
          rfq[index].deal_type_text !== undefined
            ? rfq[index].deal_type_text
            : rfq[index].leg_type,
          "LEG_TYPE",
          index + 1,
        );
        setCellValue(
          rfq[index].leg_type_text !== undefined
            ? rfq[index].leg_type_text
            : rfq[index].deal_type,
          "DEAL_TYPE",
          index + 1,
        );
        setCellValue(
          rfq[0].option_type_text !== undefined
            ? rfq[0].option_type_text
            : rfq[0].option_type,
          "OPTION_TYPE",
          index + 1,
        );
        setCellValue(rfq[index].near_date_text, "NEAR_DATE_TEXT", index + 1);
        setCellValue(rfq[index].far_date_text, "FAR_DATE_TEXT", index + 1);
        setCellValue(rfq[index].ccypair_group, "CCYPAIR_GROUP", index + 1);
        initialize_swap();

        setCellValue(rfq[index].pb_account, "PB_ACCOUNT", index + 1);
        setCellValue(rfq[index].near_notional, "NEAR_NOTIONAL", index + 1);
        setCellValue(rfq[index].far_notional, "FAR_NOTIONAL", index + 1);

        setCellValue(rfq[index].far_date, "FAR_DATE", index + 1);
        setCellValue(rfq[index].dates_in_rfq, "DATES_IN_RFQ", index + 1);
        setCellValue(rfq[index].including_ref, "INCLUDE_REF", index + 1);

        setCellValue(rfq[index].near_swap, "NEAR_SWAP", index + 1);
        setCellValue(rfq[index].far_swap, "FAR_SWAP", index + 1);

        setCellValue(rfq[index].far_fixing_date, "FAR_FIXING_DATE", index + 1);
        setCellValue(rfq[index].fixing_ref, "FIXING_REF", index + 1);
      }
    }
    assign_jexcel_data();
  };
  const set_keyval_for_all_columns = (key, col) => {
    try {
      jRef.current.jspreadsheet.ignoreEvents = true;
      let value = keyVal_input_col(key, col);
      for (let c = 1; c < jRef.current.jspreadsheet.headers.length; c++) {
        jRef.current.jspreadsheet.setValueFromCoords(
          c,
          jtable_keys.indexOf(key),
          value,
          true,
        );
      }
    } catch (err) {
      console.log(err);
    } finally {
      jRef.current.jspreadsheet.ignoreEvents = false;
    }
  };
  const setCellValue = (val, key, col) => {
    try {
      if (jRef.current.jspreadsheet !== null) {
        jRef.current.jspreadsheet.ignoreEvents = false;

        jRef.current.jspreadsheet.ignoreEvents = true;

        jRef.current.jspreadsheet.setValueFromCoords(
          col,
          keyRow(key),
          [val],
          true,
        );
      }
    } catch (err) {
      console.log(err);
    } finally {
      jRef.current.jspreadsheet.ignoreEvents = false;
    }
  };
  const eventListeners = async (event) => {
    const traderId = sessionStorage.getItem("traderId");
    const rfqMode = sessionStorage.getItem("rfqMode");
    const status = sessionStorage.getItem("status");
    let rfqStatus = sessionStorage.getItem("rfqStatus");
    if (sessionStorage.getItem("rfqId") === null || status === "copy") {
      regionEventListeners.initialize_cell_toggles(
        event,
        create_cell_toggle,
        jRef,
        jspreadsheet,
      );
      regionEventListeners.initialize_dropdown_menus(
        event,
        deal_types,
        cross_list_data,
        deal_types_single_leg,
        handleClientList,
        create_dropdown_menu_on_single_cell,
        create_dropdown_menu,
      );
      regionEventListeners.initialize_dropdown_menus_for_hedges(
        event,
        create_dropdown_menu_on_single_cell,
      );
      regionEventListeners.initialize_dropdown_menus_for_mm_hedges(
        event,
        create_dropdown_menu_on_single_cell,
      );
      regionEventListeners.initialize_dropdown_menus_for_premium_val(
        event,
        create_dropdown_menu,
      );
      regionEventListeners.initial_cell_togel_for_premium_val(
        event,
        create_cell_toggle,
      );

      regionEventListeners.build_rfq_on_ctrl_enter(
        event,
        buildRfq,
        keyVal_input_col,
        col,
        axios,
        jRef,
        setCellValue,
      );

      if (rfqMode !== "rfq booking view") {
        regionEventListeners.add_new_coluumn_F2(
          event,
          keyRow,
          jRef,
          add_custom_leg,
          col,
        );
        regionEventListeners.initial_cell_togel_for_prem_ccy_type(
          event,
          create_cell_toggle,
        );
      }
      const changeToDraftHandler = (event) => {
        if (event.keyCode == 13 && event.shiftKey) {
          dispatch(rfqSlice.setStatusCtrlEnter("Draft"));
          buildRfq(event);
        }
      };
      changeToDraftHandler(event);
      handle_arrow_navigation(event);
    } else {
      if (rfqStatus === "Booked" && rfqMode === "rfq view") {
        create_dropdown_menu("REQUEST_FOR", handleClientList(), event);
      }
      if (jRef.current !== null) {
        const token = sessionStorage.getItem("token");
        try {
          const res = await axios.get(
            `${process.env.REACT_APP_BASE_URL}` +
              END_POINT.RFQ +
              `/rfq_details/${sessionStorage.getItem("rfqId")}`,
            { headers: { Authorization: token } },
          );
          let rfqResponse = res.data.rfq;
          handle_arrow_navigation(event);

          if (status !== "copy") {
            if (rfqResponse.length > 0) {
              if (
                rfqMode === "rfq view" ||
                rfqMode === "" ||
                rfqMode === null
              ) {
                if (
                  Number(rfqResponse[0].trader_id) === Number(traderId) &&
                  (rfqResponse[0].status === "Live" ||
                    rfqResponse[0].status === "Idea" ||
                    rfqResponse[0].status === "Draft") &&
                  tableData.length === 0
                ) {
                  if (keyVal_input_col("CCYPAIR_GROUP", col) === "G10") {
                    document.addEventListener(
                      "keydown",
                      add_cut_toggle_to_g10_ccy,
                    );
                  }
                  document.addEventListener("keydown", add_swap_ccy_toggle);
                  regionEventListeners.initialize_cell_toggles(
                    event,
                    create_cell_toggle,
                    jRef,
                    jspreadsheet,
                  );
                  regionEventListeners.initialize_dropdown_menus(
                    event,
                    deal_types,
                    cross_list_data,
                    deal_types_single_leg,
                    handleClientList,
                    create_dropdown_menu_on_single_cell,
                    create_dropdown_menu,
                  );
                  regionEventListeners.initialize_dropdown_menus_for_hedges(
                    event,
                    create_dropdown_menu_on_single_cell,
                  );
                  regionEventListeners.initialize_dropdown_menus_for_mm_hedges(
                    event,
                    create_dropdown_menu_on_single_cell,
                  );
                  regionEventListeners.initialize_dropdown_menus_for_premium_val(
                    event,
                    create_dropdown_menu,
                  );
                  regionEventListeners.initial_cell_togel_for_premium_val(
                    event,
                    create_cell_toggle,
                  );
                }
              }
              if (rfqMode === "rfq booking view") {
                if (
                  rfqResponse[0].status === "Live" ||
                  rfqResponse[0].status === "Confirmed"
                ) {
                  document.addEventListener("keydown", add_swap_ccy_toggle);
                  regionEventListeners.initialize_cell_toggles(
                    event,
                    create_cell_toggle,
                    jRef,
                    jspreadsheet,
                  );
                  regionEventListeners.initialize_dropdown_menus_for_hedges(
                    event,
                    create_dropdown_menu_on_single_cell,
                  );
                  regionEventListeners.initialize_dropdown_menus_for_third_ccy(
                    event,
                    singleCurrenciesList,
                    create_dropdown_menu_on_single_cell,
                    keyVal_input_col,
                  );
                  regionEventListeners.initialize_dropdown_menus_for_mm_hedges(
                    event,
                    create_dropdown_menu_on_single_cell,
                  );
                  regionEventListeners.initialize_dropdown_menus_for_premium_val(
                    event,
                    create_dropdown_menu,
                  );
                  regionEventListeners.initial_cell_togel_for_premium_val(
                    event,
                    create_cell_toggle,
                  );
                  regionEventListeners.initial_cell_togel_for_prem_ccy_type(
                    event,
                    create_cell_toggle,
                  );
                }
              }
            }
            if (rfqResponse.length === 0) {
              regionEventListeners.initialize_cell_toggles(
                event,
                create_cell_toggle,
                jRef,
                jspreadsheet,
              );
              regionEventListeners.initialize_dropdown_menus(
                event,
                deal_types,
                cross_list_data,
                deal_types_single_leg,
                handleClientList,
                create_dropdown_menu_on_single_cell,
                create_dropdown_menu,
              );
              regionEventListeners.initialize_dropdown_menus_for_hedges(
                event,
                create_dropdown_menu_on_single_cell,
              );
              regionEventListeners.initialize_dropdown_menus_for_mm_hedges(
                event,
                create_dropdown_menu_on_single_cell,
              );
              regionEventListeners.initialize_dropdown_menus_for_premium_val(
                event,
                create_dropdown_menu,
              );
              regionEventListeners.initial_cell_togel_for_premium_val(
                event,
                create_cell_toggle,
              );
              regionEventListeners.initial_cell_togel_for_prem_ccy_type(
                event,
                create_cell_toggle,
              );
            }
          }
          if (status === "copy") {
            regionEventListeners.initialize_cell_toggles(
              event,
              create_cell_toggle,
              jRef,
              jspreadsheet,
            );
            regionEventListeners.initialize_dropdown_menus(
              event,
              deal_types,
              cross_list_data,
              deal_types_single_leg,
              handleClientList,
              create_dropdown_menu_on_single_cell,
              create_dropdown_menu,
            );
            regionEventListeners.initialize_dropdown_menus_for_hedges(
              event,
              create_dropdown_menu_on_single_cell,
            );
            regionEventListeners.initialize_dropdown_menus_for_mm_hedges(
              event,
              create_dropdown_menu_on_single_cell,
            );
            regionEventListeners.initialize_dropdown_menus_for_premium_val(
              event,
              create_dropdown_menu,
            );
            regionEventListeners.initial_cell_togel_for_premium_val(
              event,
              create_cell_toggle,
            );
          }
          regionEventListeners.build_rfq_on_ctrl_enter(
            event,
            buildRfq,
            keyVal_input_col,
            col,
            axios,
            jRef,
            setCellValue,
          );

          if (rfqMode !== "rfq booking view") {
            regionEventListeners.add_new_coluumn_F2(
              event,
              keyRow,
              jRef,
              add_custom_leg,
              col,
            );
          }
          regionEventListeners.toggleToRfqView(event, dispatch);
          regionEventListeners.toggleToAggregatorView(event, dispatch);
          regionEventListeners.toggleBookingView(event, dispatch);
        } catch (error) {
          console.log(error);
          if (error.response.status === 401) {
            console.log(error);
          }
        }

        // }
      }
    }
  };
  const handle_arrow_navigation = (event) => {
    if (["ArrowUp", "ArrowDown", "Enter"].includes(event.code) === false)
      return;
    let clone_hidden_keys = [...jspreadsheet.hidden_keys];
    let direction = 0;
    if (event.code === "ArrowUp") {
      clone_hidden_keys = clone_hidden_keys.reverse();
      direction = -1;
    }
    if (event.code === "ArrowDown" || event.code === "Enter") {
      direction = 1;
    }
    let key_row = row;
    let key_col = col;
    for (const hidden_key of clone_hidden_keys) {
      let key = jRef.current.jspreadsheet.getValueFromCoords(0, key_row);
      if (key === hidden_key) {
        jRef.current.jspreadsheet.updateSelectionFromCoords(
          key_col,
          key_row + direction,
          key_col,
          key_row + direction,
        );
        key_row += direction;
      }
    }
  };
  const create_cell_toggle = (key, choices_arr, link_all_bool, event) => {
    if (
      event.code === "Space" &&
      row === table_keys().indexOf(key) &&
      col > keyCol()
    ) {
      event.preventDefault();
      let value =
        keyVal(key) === choices_arr[0] ? choices_arr[1] : choices_arr[0];
      if (link_all_bool) {
        for (var c = 1; c < jRef.current.jspreadsheet.headers.length; c++) {
          jRef.current.jspreadsheet.setValueFromCoords(
            c,
            table_keys().indexOf(key),
            value,
            true,
          );
        }
      } else {
        jRef.current.jspreadsheet.setValueFromCoords(
          col,
          table_keys().indexOf(key),
          value,
          true,
        );
      }
    }
  };
  const create_dropdown_menu_on_single_cell = (
    cell_name,
    item_list,
    specificCol,
    event,
  ) => {
    if (
      row === keyRow(cell_name) &&
      event.code === "Space" &&
      col === specificCol
    ) {
      setCellValue("", cell_name, col);
      event.preventDefault();
      const firstLetter = event.key;
      document.removeEventListener("keydown", eventListeners);
      document.addEventListener("keydown", auto_fill_dropdown_event);
      dropDownList.addDowpDown(
        jRef.current.jspreadsheet,
        getCell(col, row),
        item_list,
        firstLetter,
      );
    }
  };
  const create_dropdown_menu = (cell_name, item_list, event, specificCol) => {
    if (
      row === keyRow(cell_name) &&
      event.code === "Space" &&
      col > keyCol(cell_name)
    ) {
      setCellValue("", cell_name, col);
      event.preventDefault();

      document.removeEventListener("keydown", eventListeners);
      // if(rfqMode !== "rfq booking view"){
      document.addEventListener("keydown", auto_fill_dropdown_event);
      // }
      dropDownList.addDowpDown(
        jRef.current.jspreadsheet,
        getCell(col, row),
        item_list,
      );
    }
  };
  const auto_fill_dropdown_event = () => {
    const getUserSelection = new Promise((resolve, reject) => {
      setTimeout(() => {
        let elements = document.getElementsByClassName("jdropdown-description");
        if (elements.length === 1) {
          resolve(elements[0].innerText);
        }
      }, 500);
    });
    getUserSelection.then((result) => {
      let cell = getCell(col, row);
      dropDownList.closeEditor(jRef.current.jspreadsheet, cell, result);
    });
  };
  const is_vanilla_only = () => {
    let is_only_vanillas = true;
    for (
      let index = 1;
      index < jRef.current.jspreadsheet.headers.length;
      index++
    ) {
      let option_type = keyVal_input_col("OPTION_TYPE", index);

      if (option_type === "EXOTIC") {
        is_only_vanillas = false;
      }
    }
    return is_only_vanillas;
  };
  const test_numeric_input = (key, col) => {
    let userInput = keyVal_input_col(key, col);

    if (userInput === "") {
      return;
    }

    let is_numeric = true;

    if (!/^([0-9]+)?([.][0-9]+)?$/.test(userInput)) {
      dispatch(
        actionSnackBar.setSnackBar(
          "error",
          `${userInput} is not a valid ${key}. Please enter a number`,
          3000,
        ),
      );
      is_numeric = false;
      setCellValue("", key, col);
    }
    return is_numeric;
  };
  const assign_jexcel_data = () => {
    rfq_object = {
      ...rfq_object,
      rfq_id: sessionStorage.getItem("rfqId"),
      rfq_table_data: jRef.current.jspreadsheet.getJson(),
    };
    dispatch(rfqPostTradeAction.setRfqObject(rfq_object));
    setrfq_object(rfq_object);
  };

  let updateRfqBookingModeDetails = async (rfqObjectToUpdate) => {
    let rfqId = sessionStorage.getItem("rfqId");
    const token = sessionStorage.getItem("token");

    try {
      let response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}` + `rfq/rfq_booking_mode`,
        rfqObjectToUpdate,
        {
          headers: { Authorization: token },
        },
      );

      let bookingModeUpdatingData = await axios.get(
        `${process.env.REACT_APP_BASE_URL}` +
          "rfq" +
          `/rfq_booking_details/${rfqId}`,
        {
          headers: { Authorization: token },
        },
      );

      if (bookingModeUpdatingData.data.rfqDetails.length > 0) {
        dispatch(
          rfqSlice.updateRfqBookingModeUpdatingRows(
            bookingModeUpdatingData.data.rfqDetails,
          ),
        );
      } else {
        dispatch(rfqSlice.updateRfqBookingModeUpdatingRows([]));
      }
    } catch (error) {
      console.log(error);
    }
  };
  let getRfqBookingModeDetails = async () => {
    const token = sessionStorage.getItem("token");
    try {
      let response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}` +
          "rfq" +
          `/rfq_booking_details/${sessionStorage.getItem("rfqId")}`,
        {
          headers: { Authorization: token },
        },
      );

      dispatch(
        rfqSlice.updateRfqBookingModeUpdatingRows(response.data.rfqDetails),
      );
    } catch (error) {
      console.log(error);
    }
  };
  const add_cut_toggle_to_g10_ccy = (event) => {
    create_cell_toggle("CUT", ["NYK1000", "TOK1500"], true, event);
  };
  const set_expiry = (col) => {
    try {
      jRef.current.jspreadsheet.options.allowInsertColumn = true;
      let expiry = keyVal_input_col("EXPIRY_TEXT", col);
      let specs = get_deal_specs(col);

      if (specs?.linked_expiry) {
        for (let c = 1; c < jRef.current.jspreadsheet.headers.length; c++) {
          jRef.current.jspreadsheet.setValueFromCoords(
            c,
            table_keys().indexOf("EXPIRY_TEXT"),
            expiry,
            true,
          );
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      jRef.current.jspreadsheet.options.allowInsertColumn = false;
    }
  };
  const cellId = (col, row) => {
    return `${alphabet[col].toUpperCase()}${row}`;
  };
  const add_custom_leg = () => {
    let leg_data = jRef.current.jspreadsheet.getColumnData(
      jRef.current.jspreadsheet.headers.length - 1,
    );
    addNewColumn();
    try {
      jRef.current.jspreadsheet.ignoreEvents = true;
      remove_readonly_class();
      jRef.current.jspreadsheet.setColumnData(
        jRef.current.jspreadsheet.headers.length - 1,
        leg_data,
      );

      // reset_rfq_instance();
    } catch (err) {
      console.log(err);
    } finally {
      jRef.current.jspreadsheet.ignoreEvents = false;
    }

    set_custom_deal_type();
    if (jRef.current.jspreadsheet.headers.length > 3) {
      for (
        let index = 1;
        index < jRef.current.jspreadsheet.headers.length;
        index++
      ) {
        setCellValue("PCT_NOTIONAL", "RFQ_FORMAT", index);
        setCellValue("FWD", "REF_TYPE", index);
        setCellValue("YES", "INCLUDE_REF", index);
      }
    }
  };
  const remove_readonly_class = () => {
    for (let col = 0; col < jRef.current.jspreadsheet.headers.length; col++) {
      for (
        let index = 0;
        index < jRef.current.jspreadsheet.rows.length - 1;
        index++
      ) {
        const cell = getCell(col, index);
        cell.classList.remove("readonly");
      }
    }
  };
  const delete_leg = () => {
    if (jRef.current.jspreadsheet.headers.length > 2) {
      //   if (second_last_column > 0) {
      jRef.current.jspreadsheet.deleteColumn(2);
    }
    // }
    for (
      let index = 1;
      index < jRef.current.jspreadsheet.headers.length;
      index++
    ) {
      jRef.current.jspreadsheet.headers[index].innerText = `Leg ${index}`;
    }
    // reset_rfq_instance();
  };
  const format_table = () => {
    let rfqDealType = sessionStorage.getItem("rfqDealType");

    addArrowIcon();
    // addSwitchIcon()
    regionFormat.format_key_group(
      user_editable_cells,
      "#003366",
      "white",
      "bolder",
    );

    regionFormat.format_key_group(
      market_data_keys,
      "#003366",
      "#F2E7DE",
      "bolder",
    );

    // regionFormat.format_key_group(hedge_keys, "#96E6B3", "#3C4B63", "normal");
    regionFormat.format_key_group(
      spacebar_keys,
      "#000000",
      "#F0F8FF",
      "normal",
    );

    // regionFormat.remove_format_underline("LEG_TYPE", jRef, cellId, keyRow);

    // regionFormat.format_underline("NET_MARKET_PRICE", jRef, cellId, keyRow);

    if (rfqDealType === "SWAP") {
      regionFormat.format_underline("LEG_TYPE", jRef, cellId, keyRow);
      regionFormat.format_underline("SWAP_CCY", jRef, cellId, keyRow);
      regionFormat.format_underline("DATES_IN_RFQ", jRef, cellId, keyRow);
    } else {
      regionFormat.remove_format_underline("LEG_TYPE", jRef, cellId, keyRow);
      regionFormat.format_underline("OPTION_TYPE", jRef, cellId, keyRow);
      regionFormat.format_underline("CCYPAIR_GROUP", jRef, cellId, keyRow);
      regionFormat.format_underline("CUT", jRef, cellId, keyRow);
      regionFormat.format_underline("DELTA", jRef, cellId, keyRow);
      regionFormat.format_underline("EXPIRY_TEXT", jRef, cellId, keyRow);
      regionFormat.format_underline("MM_HEDGE_TYPE", jRef, cellId, keyRow);
    }
    if (rfqMode === "rfq booking view") {
      if (rfq.length === 0) {
        if (rfq_object.rfq_table_data !== null) {
          if (rfq_object?.rfq_table_data[0][2] === undefined) {
            regionFormat.format_underline(
              "NET_CLIENT_PRICE",
              jRef,
              cellId,
              keyRow,
            );
          } else {
            regionFormat.format_underline("HEDGE_TYPE", jRef, cellId, keyRow);
          }
        }
      }
      if (rfq.length === 1)
        regionFormat.format_underline("NET_CLIENT_PRICE", jRef, cellId, keyRow);
    }
    if (rfq.length > 1) {
      regionFormat.remove_format_underline(
        "NET_MARKET_PRICE",
        jRef,
        cellId,
        keyRow,
      );
      regionFormat.format_underline("HEDGE_TYPE", jRef, cellId, keyRow);
    }
  };
  const addArrowIcon = () => {
    let rowWithDropDown = [
      "CCY_PAIR",
      "DEAL_TYPE",
      "LEG_TYPE",
      "HEDGE_TYPE",
      "REQUEST_FOR",
      "PREMIUM_TYPE",
      "CLIENT_HEDGE_TYPE",
      "MM_HEDGE_TYPE",
      "PB_ACCOUNT",
      "THIRD_CCY",
    ];
    let rowWithDToggle = [
      "CALL_PUT",
      "BUY_SELL",
      "RFQ_FORMAT",
      "REF_TYPE",
      "INCLUDE_REF",
      "CUT",
      "PREMIUM_VAL",
      "HEDGE_MATCH_TYPE",
      "NOTIONAL_CCY",
      "SWAP_CCY",
      "DATES_IN_RFQ",
      "SWAP_DIRECTION",
      "PREM_CCY_TYPE",
    ];
    jRef.current.jspreadsheet.ignoreEvents = true;
    for (let c = 1; c < jRef.current.jspreadsheet.headers.length; c++) {
      rowWithDropDown.forEach((row) => {
        let key = getCell(c, keyRow(row));
        key.classList.add("arrow-icon");
      });
      rowWithDToggle.forEach((row) => {
        let key = getCell(c, keyRow(row));
        key.classList.add("toggle-icon");
      });
      //toggle-icon
    }
    jRef.current.jspreadsheet.ignoreEvents = false;
  };
  const addSwitchIcon = () => {
    let spacebarKeys = [
      "CALL_PUT",
      "BUY_SELL",
      "CUT",
      "PB_ACCOUNT",
      "RFQ_FORMAT",
      "REF_TYPE",
      "INCLUDE_REF",
      "PREMIUM_VAL",
      "PREMIUM_TYPE",
      "REQUEST_FOR",
      "DATES_IN_RFQ",
      "SWAP_CCY",
    ];
    jRef.current.jspreadsheet.ignoreEvents = true;
    for (let c = 1; c < jRef.current.jspreadsheet.headers.length; c++) {
      spacebarKeys.forEach((row) => {
        let key = getCell(c, keyRow(row));
        key.classList.add("toggle-icon");
      });
    }
    jRef.current.jspreadsheet.ignoreEvents = false;
  };
  const create_rfq_description = () => {
    let desc = `${keyVal_input_col("CCY_PAIR", 1)}_${keyVal_input_col(
      "DEAL_TYPE",
      1,
    )}`;

    rfq_object = { ...rfq_object, cross: keyVal_input_col("CCY_PAIR", 1) };
    rfq_object = { ...rfq_object, rfq_description: desc };
    return {
      cross: keyVal_input_col("CCY_PAIR", 1),
      rfq_description: desc,
    };
  };
  const subToRfqQuote = (newCreatedRfqId) => {
    const token = sessionStorage.getItem("token");

    if (ws !== null) {
      sendEvent({
        type: "subscribe_to_rfq_quote_id",
        data: { rfqId: `${newCreatedRfqId}` },
        metaData: {},
      });
    } else {
      connectWS(sessionStorage.getItem("token"), dispatch, rfqSlice);
      sendEvent({
        type: "subscribe_to_rfq_quote_id",
        data: { rfqId: `${newCreatedRfqId}` },
        metaData: {},
      });
    }
  };
  const validatePreServerRequest = async () => {
    let isStrikeDisabled = false;

    for (let index = 0; index < 4; index++) {
      if (
        keyVal_input_col("LEG_TYPE", index) === "DNT" ||
        keyVal_input_col("LEG_TYPE", index) === "OT" ||
        keyVal_input_col("LEG_TYPE", index) === "NT"
      ) {
        isStrikeDisabled = true;
      }
    }
    if (isStrikeDisabled) {
      if (
        keyVal_input_col("CCY_PAIR", 1) === "" ||
        keyVal_input_col("CUT", 1) === "" ||
        keyVal_input_col("NOTIONAL", 1) === "" ||
        keyVal_input_col("NOTIONAL", 2) === "" ||
        keyVal_input_col("SPOT_REF", 1) === "" ||
        keyVal_input_col("FWD_REF", 1) === "" ||
        keyVal_input_col("EXPIRY", 1) === "" ||
        keyVal_input_col("DELIVERY", 2) === "" ||
        keyVal_input_col("CCY_PAIR", 2) === "" ||
        keyVal_input_col("CUT", 2) === "" ||
        keyVal_input_col("NOTIONAL", 2) === "" ||
        keyVal_input_col("NOTIONAL", 2) === "" ||
        keyVal_input_col("SPOT_REF", 2) === "" ||
        keyVal_input_col("FWD_REF", 2) === "" ||
        keyVal_input_col("EXPIRY", 2) === "" ||
        keyVal_input_col("DELIVERY", 2) === "" ||
        keyVal_input_col("CCY_PAIR", 3) === "" ||
        keyVal_input_col("CUT", 3) === "" ||
        keyVal_input_col("NOTIONAL", 3) === "" ||
        keyVal_input_col("NOTIONAL", 3) === "" ||
        keyVal_input_col("SPOT_REF", 3) === "" ||
        keyVal_input_col("FWD_REF", 3) === "" ||
        keyVal_input_col("EXPIRY", 3) === "" ||
        keyVal_input_col("DELIVERY", 3) === "" ||
        keyVal_input_col("CCY_PAIR", 4) === "" ||
        keyVal_input_col("CUT", 4) === "" ||
        keyVal_input_col("NOTIONAL", 4) === "" ||
        keyVal_input_col("NOTIONAL", 4) === "" ||
        keyVal_input_col("SPOT_REF", 4) === "" ||
        keyVal_input_col("FWD_REF", 4) === "" ||
        keyVal_input_col("EXPIRY", 4) === "" ||
        keyVal_input_col("DELIVERY", 4) === ""
      ) {
        return true;
      }
    } else {
      if (
        keyVal_input_col("CCY_PAIR", 1) === "" ||
        // keyVal_input_col("STRIKE", 1) === "" ||
        keyVal_input_col("CUT", 1) === "" ||
        keyVal_input_col("NOTIONAL", 1) === "" ||
        keyVal_input_col("NOTIONAL", 2) === "" ||
        keyVal_input_col("SPOT_REF", 1) === "" ||
        keyVal_input_col("FWD_REF", 1) === "" ||
        keyVal_input_col("EXPIRY", 1) === "" ||
        keyVal_input_col("DELIVERY", 2) === "" ||
        keyVal_input_col("CCY_PAIR", 2) === "" ||
        keyVal_input_col("STRIKE", 2) === "" ||
        keyVal_input_col("CUT", 2) === "" ||
        keyVal_input_col("NOTIONAL", 2) === "" ||
        keyVal_input_col("NOTIONAL", 2) === "" ||
        keyVal_input_col("SPOT_REF", 2) === "" ||
        keyVal_input_col("FWD_REF", 2) === "" ||
        keyVal_input_col("EXPIRY", 2) === "" ||
        keyVal_input_col("DELIVERY", 2) === "" ||
        keyVal_input_col("CCY_PAIR", 3) === "" ||
        keyVal_input_col("STRIKE", 3) === "" ||
        keyVal_input_col("CUT", 3) === "" ||
        keyVal_input_col("NOTIONAL", 3) === "" ||
        keyVal_input_col("NOTIONAL", 3) === "" ||
        keyVal_input_col("SPOT_REF", 3) === "" ||
        keyVal_input_col("FWD_REF", 3) === "" ||
        keyVal_input_col("EXPIRY", 3) === "" ||
        keyVal_input_col("DELIVERY", 3) === "" ||
        keyVal_input_col("CCY_PAIR", 4) === "" ||
        keyVal_input_col("STRIKE", 4) === "" ||
        keyVal_input_col("CUT", 4) === "" ||
        keyVal_input_col("NOTIONAL", 4) === "" ||
        keyVal_input_col("NOTIONAL", 4) === "" ||
        keyVal_input_col("SPOT_REF", 4) === "" ||
        keyVal_input_col("FWD_REF", 4) === "" ||
        keyVal_input_col("EXPIRY", 4) === "" ||
        keyVal_input_col("DELIVERY", 4) === ""
      ) {
        return true;
      }
    }
    return false;
  };
  const set_quote_type_api = async () => {
    try {
      let table_data = jRef.current.jspreadsheet.getJson();
      let response = await axios.post(
        `${process.env.REACT_APP_PYTON}` + `set_quote_type`,
        table_data,
      );
      Object.assign(rfq_object, {
        quote_type: response.data,
      });
    } catch (error) {
      console.log(error);
    }
  };
  const handleStatusUpdate = async (e) => {
    for (
      let index = 0;
      index < jRef.current.jspreadsheet.headers.length - 1;
      index++
    ) {
      await initialize_rfq(
        keyVal_input_col("CCY_PAIR", index + 1),
        keyVal_input_col("EXPIRY_TEXT", index + 1),
        index + 1,
      );
      if (await validatePreServerRequest()) {
        dispatch(
          actionSnackBar.setSnackBar(
            "error",
            "Wait for rfq to initialize...",
            3000,
          ),
        );
        return;
      } else {
        let cross = keyVal_input_col("CCY_PAIR", col);
        let expiryText = keyVal_input_col("EXPIRY_TEXT", col);
        let response = await axios.post(
          `${process.env.REACT_APP_PYTON}` + `initialize_rfq`,
          { cross: cross, expiry_text: expiryText },
        );
        let results = response.data;
        for (
          let index = 1;
          index < jRef.current.jspreadsheet.headers.length;
          index++
        ) {
          setCellValue(results.expiry_text, "EXPIRY_TEXT", index);
        }
        create_rfq_description();

        await get_opts_dets_from_api(true, col);
        await set_quote_type_api();
        await regionApiCalls.get_rfq_strings(
          jRef,
          rfq_object,
          newRfqCreatedDetails,
          dispatch,
          rfqSlice.updateOvmlTail,
          rfqSlice.updateRfqStr,
          axios,
        );

        const notional_ccy = keyVal_input_col("NOTIONAL_CCY", 1);
        let strategyId = get_deal_specs(1);
        let optionLegId = strategyId.leg_type_id;
        const currencyPair = keyVal_input_col("CCY_PAIR", 1);
        let currencyPairId = cross_list_data.find((x) => {
          return x.value == currencyPair;
        });
        let pbAccount = keyVal_input_col("PB_ACCOUNT", 1);

        let pbAccountiD = pb.find((x) => {
          return x.name.toUpperCase() === pbAccount.toUpperCase();
        });
        rfq_object.rfq_str = newRfqCreatedDetails[0].rfq_str;
        rfq_object.ovml_tail = newRfqCreatedDetails[0].ovml_tail;
        rfq_object.strategyId = strategyId.strategy_id;
        rfq_object.currencyPairId = currencyPairId.id;
        rfq_object.traderNote = traderNote;
        rfq_object.traderId = traderId === 0 ? "" : traderId;
        rfq_object.pb_account = pbAccountiD.id;
        rfq_object.optionLegId = optionLegId;
        rfq_object.cut = currencyPairId.id;
        rfq_object.notional_ccy = notional_ccy;

        assign_jexcel_data();
      }
    }
  };
  const buildRfq = () => {
    if (keyVal_input_col("LEG_TYPE", 1) === "SWAP") {
      build_rfq_swap();
    } else {
      buildRfqObject();
    }
  };
  const build_rfq_swap = async () => {
    const validate_build = [
      keyVal_input_col("NEAR_DATE", 1),
      keyVal_input_col("FAR_DATE", 1),
      keyVal_input_col("NEAR_NOTIONAL", 1),
      keyVal_input_col("FAR_NOTIONAL", 1),
      keyVal_input_col("NEAR_SWAP", 1),
      keyVal_input_col("FAR_SWAP", 1),
    ];
    if (validate_build.includes("")) {
      dispatch(actionSnackBar.setSnackBar("SOME RFQ INPUTS ARE MISSING..."));
      return;
    }
    await get_rfq_swap_string();
    create_swap_description();
    const notional_ccy = keyVal_input_col("NOTIONAL_CCY", 1);
    let strategyId = get_deal_specs(1);
    let optionLegId = strategyId.leg_type_id;
    const currencyPair = keyVal_input_col("CCY_PAIR", 1);
    let currencyPairId = cross_list_data.find((x) => {
      return x.value == currencyPair;
    });
    let pbAccount = keyVal_input_col("PB_ACCOUNT", 1);

    let pbAccountiD = pb.find((x) => {
      return x.name.toUpperCase() === pbAccount.toUpperCase();
    });
    rfq_object.rfq_str = newRfqCreatedDetails[0].rfq_str;
    rfq_object.ovml_tail = newRfqCreatedDetails[0].ovml_tail;
    rfq_object.strategyId = strategyId.strategy_id;
    rfq_object.currencyPairId = currencyPairId.id;
    rfq_object.traderNote = traderNote;
    rfq_object.traderId = traderId === 0 ? "" : traderId;
    rfq_object.pb_account = pbAccountiD.id;
    rfq_object.optionLegId = optionLegId;
    rfq_object.cut = currencyPairId.id;
    rfq_object.notional_ccy = notional_ccy;
    rfq_object.quote_type = "SWAP";
    assign_jexcel_data();
    setLaunchStatus(true);

    sendRfqToDb();
  };
  const create_swap_description = () => {
    let desc = `${keyVal_input_col("CCY_PAIR", 1)}_${keyVal_input_col(
      "LEG_TYPE",
      1,
    )}`;

    rfq_object = { ...rfq_object, cross: keyVal_input_col("CCY_PAIR", 1) };
    rfq_object = { ...rfq_object, rfq_description: desc };
    return {
      cross: keyVal_input_col("CCY_PAIR", 1),
      rfq_description: desc,
    };
  };
  const get_rfq_swap_string = async () => {
    try {
      let jtable = jRef.current.jspreadsheet.getJson();
      let response = await axios.post(
        `${process.env.REACT_APP_PYTON}` + `get_rfq_swap_string`,
        { jtable: jtable },
      );
      dispatch(rfqSlice.updateOvmlTail(response.data.ovml_tail));
      dispatch(rfqSlice.updateRfqStr(response.data.rfq));
      newRfqCreatedDetails.push({
        rfq_str: response.data.rfq,
        ovml_tail: response.data.ovml_tail,
      });
      return {
        rfq_str: response.data.rfq,
        ovml_tail: response.data.ovml_tail,
      };
    } catch (error) {
      console.log(error);
    }
  };
  const buildRfqObject = async () => {
    const legType = keyVal_input_col("DEAL_TYPE", 1);
    if (legType !== "SWAP" && (await validatePreServerRequest())) {
      dispatch(
        actionSnackBar.setSnackBar(
          "error",
          "Wait for rfq to initialize...",
          3000,
        ),
      );
      return;
    } else {
      create_rfq_description();

      await get_opts_dets_from_api(true, col);
      await set_quote_type_api();
      await regionApiCalls.get_rfq_strings(
        jRef,
        rfq_object,
        newRfqCreatedDetails,
        dispatch,
        rfqSlice.updateOvmlTail,
        rfqSlice.updateRfqStr,
        axios,
      );

      const notional_ccy = keyVal_input_col("NOTIONAL_CCY", 1);
      let strategyId = get_deal_specs(1);
      let optionLegId = strategyId.leg_type_id;
      const currencyPair = keyVal_input_col("CCY_PAIR", 1);
      let currencyPairId = cross_list_data.find((x) => {
        return x.value == currencyPair;
      });
      let pbAccount = keyVal_input_col("PB_ACCOUNT", 1);

      let pbAccountiD = pb.find((x) => {
        return x.name.toUpperCase() === pbAccount.toUpperCase();
      });
      rfq_object.rfq_str = newRfqCreatedDetails[0].rfq_str;
      rfq_object.ovml_tail = newRfqCreatedDetails[0].ovml_tail;
      rfq_object.strategyId = strategyId.strategy_id;
      rfq_object.currencyPairId = currencyPairId.id;
      rfq_object.traderNote = traderNote;
      rfq_object.traderId = traderId === 0 ? "" : traderId;
      rfq_object.pb_account = pbAccountiD.id;
      rfq_object.optionLegId = optionLegId;
      rfq_object.cut = currencyPairId.id;
      rfq_object.notional_ccy = notional_ccy;

      assign_jexcel_data();
      setLaunchStatus(true);

      sendRfqToDb();
      // }
    }
  };
  const sendRfqToDb = async () => {
    const token = sessionStorage.getItem("token");
    try {
      const statusUpdated = await dispatch(rfqSlice.getStatus());
      if (statusUpdated === "copy") {
        dispatch(rfqSlice.createRfqCtrlEnter(rfq_object)).then(
          async (modifiedRfqObject) => {
            let response = await axios.post(
              `${process.env.REACT_APP_BASE_URL}` + `rfq`,
              modifiedRfqObject,
              {
                headers: { Authorization: token },
              },
            );
            newRfqCreatedDetails = [];
            getRecomandation(
              response.data.newRfq[0].rfq_id,
              response.data.newRfq[0].created_on,
              response.data.newRfq[0].ccy_pair,
              rfq_object,
            );

            dispatch(rfqSlice.updatRfqButtonStatus(""));
            sessionStorage.setItem("status", "open");
            dispatch(
              rfqSlice.updateRequestFor(response.data.rfqDetails[0].client_id),
            );

            dispatch(rfqSlice.setStatusCtrlEnter(""));

            rfq_object = { ...modifiedRfqObject, rfq_id: response.data.id };
            sessionStorage.setItem("rfqId", response.data.id);
            dispatch(rfqSlice.getRfqById(response.data.id));

            dispatch(rfqSlice.updateCreatedRfqDetails(response.data.newRfq));
            dispatch(rfqSlice.clearTraderNote());
            const activeRfqArray = activeRfqInstanceList.filter(
              (rfq) => rfq.id !== undefined,
            );
            let newRfq = {
              rfq_description: rfq_object.rfq_description,
              id: Number(response.data.id),
              newRfq: true,
            };

            // dispatch(rfqSlice.addRfqInstanceList(newRfq));
            const obj = {
              tab: newRfq,
              userId: sessionStorage.getItem("id"),
              action: "add tab",
              tabIsChosen: true,
            };
            const res = await axios.post(
              `${process.env.REACT_APP_BASE_URL}` + END_POINT.UPDATE_TABS,
              obj,
              { headers: { Authorization: token } },
            );
            dispatch(rfqSlice.updatRfqInstanceList(res.data));

            setLaunchStatus(true);
            subToRfqQuote(response.data.id);
            get_quote_parameters(rfq_object.rfq_table_data);
          },
        );
      } else if (
        (sessionStorage.getItem("rfqId") === undefined ||
          sessionStorage.getItem("rfqId") === null) &&
        rfq.length === 0 &&
        statusUpdated !== "copy"
      ) {
        dispatch(rfqSlice.createRfqCtrlEnter(rfq_object)).then(
          async (modifiedRfqObject) => {
            let response = await axios.post(
              `${process.env.REACT_APP_BASE_URL}` + `rfq`,
              modifiedRfqObject,
              {
                headers: { Authorization: token },
              },
            );
            newRfqCreatedDetails = [];
            dispatch(rfqSlice.updatRfqButtonStatus(""));
            sessionStorage.setItem("status", "open");
            sessionStorage.setItem("rfqMode", "rfq view");

            dispatch(
              rfqSlice.updateRequestFor(response.data.rfqDetails[0].client_id),
            );
            dispatch(rfqSlice.setStatusCtrlEnter(""));

            rfq_object = { ...modifiedRfqObject, rfq_id: response.data.id };
            rfq_object = { ...modifiedRfqObject, newRfq: response.data.newRfq };

            getRecomandation(
              response.data.newRfq[0].rfq_id,
              response.data.newRfq[0].created_on,
              response.data.newRfq[0].ccy_pair,
              rfq_object,
            );
            sessionStorage.setItem("rfqId", response.data.id);

            setCurrentRfqId(response.data.id);
            dispatch(rfqSlice.updateCreatedRfqDetails(response.data.newRfq));
            dispatch(rfqSlice.clearTraderNote());
            const activeRfqArray = activeRfqInstanceList.filter(
              (rfq) => rfq.id !== undefined,
            );
            let newRfq = {
              rfq_description: rfq_object.rfq_description,
              id: Number(response.data.id),
              newRfq: true,
            };
            // activeRfqArray.push(newRfq);
            // dispatch(rfqSlice.addRfqInstanceList(newRfq));
            const obj = {
              tab: newRfq,
              userId: sessionStorage.getItem("id"),
              action: "add tab",
              tabIsChosen: true,
            };
            const res = await axios.post(
              `${process.env.REACT_APP_BASE_URL}` + END_POINT.UPDATE_TABS,
              obj,
              { headers: { Authorization: token } },
            );
            dispatch(rfqSlice.updatRfqInstanceList(res.data));

            setLaunchStatus(true);
            subToRfqQuote(response.data.id);
            get_quote_parameters(rfq_object.rfq_table_data);
          },
        );
      } else if (
        sessionStorage.getItem("rfqId") !== null &&
        sessionStorage.getItem("rfqId") !== undefined &&
        sessionStorage.getItem(status) !== "copy" &&
        sessionStorage.getItem(status) !== "newRfq"
      ) {
        let rfqId = sessionStorage.getItem("rfqId");

        dispatch(rfqSlice.createRfqCtrlEnter(rfq_object)).then(
          async (modifiedRfqObject) => {
            rfq_object = {
              ...modifiedRfqObject,
              quote_type: rfq_object.quote_type,
            };
            let quoteParameters = await updateQuoteparameters();
            dispatch(
              rfqAggregatorSlice.updateQuoteParameterts(quoteParameters),
            );
            dispatch(
              rfqAggregatorSlice.setRfqDefaultQuoteParams(quoteParameters),
            );
            dispatch(rfqSlice.setStatusCtrlEnter(""));

            let dealStats = await updateDealStats(quoteParameters);
            rfq_object = {
              ...modifiedRfqObject,
              quoteParameters: quoteParameters,
              dealStats: dealStats,
            };

            let response = await axios.put(
              `${process.env.REACT_APP_BASE_URL}` + `rfq/${rfqId}`,
              rfq_object,
              {
                headers: { Authorization: token },
              },
            );

            newRfqCreatedDetails = [];

            getRecomandation(
              response.data.rfq[0].rfq_id,
              response.data.rfq[0].created_on,
              response.data.rfq[0].ccy_pair,
              rfq_object,
            );
            sessionStorage.setItem("rfqId", response.data.rfq[0].rfq_id);
            // dispatch(rfqSlice.clearDealStatsData());
            dispatch(rfqSlice.getRfqByIdSuccess(response.data.rfqDeatails));
            dispatch(rfqSlice.updateTableData([]));
            dispatch(rfqSlice.updateIsRfqStatusUpdateLive(false));
            setLaunchStatus(true);
            subToRfqQuote(response.data.rfq[0].rfq_id);
          },
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleOnClickClose = () => {
    dispatch(uiSlice.ChangeModalStatus(false));
  };
  const openModal = () => {
    if (rfq.length > 0) {
      dispatch(uiSlice.ChangeModalStatus(true));
    }
  };
  const reset = () => {
    let col_num = jRef.current.jspreadsheet.headers.length;
    for (let index = 2; index < col_num; index++) {
      jRef.current.jspreadsheet.deleteColumn(index);
    }

    for (const key of jtable_keys) {
      setCellValue("", key, 1);
    }
    let cell = getCell(1, keyRow("DEAL_TYPE"));
    cell.classList.remove("dropDownCells");
    setLaunchStatus(false);
  };
  const refresh_opts_dets_from_api = async (is_user_vol, col) => {
    const is_vanillas_only = is_vanilla_only();
    if (is_vanillas_only) {
      await refresh_vanilla_dets(is_user_vol, col);
    } else {
      await refresh_exotic_dets(col);
    }
  };
  const refresh_vanilla_dets = async (is_user_vol, col) => {
    let table_data = jRef.current.jspreadsheet.getJson();

    try {
      let response = await axios.post(
        `${process.env.REACT_APP_PYTON}` + `get_vanilla_opt_dets`,
        { is_user_vol: is_user_vol, opts_data: table_data },
      );
      let results = response.data;
      for (
        let index = 0;
        index < jRef.current.jspreadsheet.headers.length - 1;
        index++
      ) {
        setCellValue(
          results.ui_dets[index].delta.toFixed(2),
          "DELTA",
          index + 1,
        );
        setCellValue(
          results.ui_dets[index].hedge_amount.toFixed(2),
          "HEDGE_AMOUNT",
          index + 1,
        );
        setCellValue(
          results.ui_dets[index].price_pct_notional.toFixed(4),
          "PRICE_PCT_NOTIONAL",
          index + 1,
        );
        setCellValue(results.ui_dets[index].vega.toFixed(0), "VEGA", index + 1);
      }
      setCellValue(
        results.net_prices.net_client_price.toFixed(6),
        "NET_CLIENT_PRICE",
        1,
      );
      setCellValue(
        results.net_prices.net_market_price.toFixed(6),
        "NET_MARKET_PRICE",
        1,
      );

      for (let idx = 0; idx < results.hedges.hedge_delta.length; idx++) {
        setCellValue(results.hedges.hedge_delta[idx], "HEDGE_DELTA", idx + 1);
        setCellValue(
          results.hedges.mm_hedge_delta[idx],
          "MM_HEDGE_DELTA",
          idx + 1,
        );
        setCellValue(
          results.hedges.client_hedge_delta[idx],
          "CLIENT_HEDGE_DELTA",
          idx + 1,
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
  const refresh_exotic_dets = async (col) => {
    let table_data = jRef.current.jspreadsheet.getJson();
    try {
      let response = await axios.post(
        `${process.env.REACT_APP_PYTON}` + `get_exotic_opt_dets`,
        { opts_data: table_data },
      );
      let results = response.data;
      for (let idx = 0; idx < results.hedges.hedge_delta.length; idx++) {
        setCellValue(results.hedges.hedge_delta[idx], "HEDGE_DELTA", idx + 1);
      }
      // assign_jexcel_data()
    } catch (error) {
      console.log(error);
    }
  };
  const api_initialize_deal_stats = async () => {
    try {
      let response = await axios.post(
        `${process.env.REACT_APP_PYTON}` + `initialize_deal_stats`,
        {
          quote_type: rfq_object.quote_type,
          rfq_table_data: rfq_object.rfq_table_data,
          quote_parameters: rfq_object.quote_parameters,
        },
      );

      UpdateDealStatsData(response.data);
      setrfq_object(rfq_object);

      dispatch(rfqSlice.updateDealStatsData(response.data));
    } catch (error) {
      console.log(error);
    }
  };
  const updateDealStats = async (quoteParameters) => {
    try {
      let response = await axios.post(
        `${process.env.REACT_APP_PYTON}` + `initialize_deal_stats`,
        {
          quote_type: quoteParameters.quote_type,
          rfq_table_data: rfq_object.rfq_table_data,
          quote_parameters: quoteParameters,
        },
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };
  const getRecomandation = async (rfqId, createdOn, cross, rfq_object) => {
    rfq_object = { ...rfq_object, cross: cross };
    rfq_object = { ...rfq_object, created_on: createdOn };
    rfq_object = { ...rfq_object, rfq_id: rfqId };
    try {
      const response = await axios.post(
        "https://fx-letool.letool-engine.makor-group.com/api/get_recommendations",
        rfq_object,
      );
      dispatch(
        rfqSlice.rfqRecommendationHandler({
          recommendationDetails: response.data,
          rfqId: rfqId,
        }),
      );
    } catch (error) {
      console.log(error);
    }
  };
  const addQuoteParametersTodb = async (quoteParameters) => {
    dispatch(rfqAggregatorSlice.updateQuoteParameterts(quoteParameters));
    dispatch(rfqAggregatorSlice.setRfqDefaultQuoteParams(quoteParameters));
    const token = sessionStorage.getItem("token");
    let response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}` + `quote_parameters`,
      quoteParameters,
      {
        headers: { Authorization: token },
      },
    );
  };
  const get_quote_parameters = async (tableData) => {
    try {
      let response = await axios.post(
        `${process.env.REACT_APP_PYTON}` + `initialize_quote_parameters`,
        {
          rfq_table_data: tableData,
          quote_type: rfq_object.quote_type,
        },
      );
      Object.assign(rfq_object, {
        quote_parameters: response.data,
      });

      response.data.id = sessionStorage.getItem("rfqId");
      response.data.quote_type = rfq_object.quote_type;
      if (response.data.quote_type === "DOUBLE_LEG_VOL") {
        response.data.bbg_vol = response.data.bbg_vols;
      }
      addQuoteParametersTodb(response.data);
      rfq_object = { ...rfq_object, quote_parameters: response.data };
      if (
        (rfq_object.deal_stats === null && dealStatsData.makor_bid === "---") ||
        rfq_object?.deal_stats?.client_price_string === "---"
      ) {
        api_initialize_deal_stats();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const updateQuoteparameters = async () => {
    try {
      let response = await axios.post(
        `${process.env.REACT_APP_PYTON}` + `initialize_quote_parameters`,
        {
          rfq_table_data: jRef.current.jspreadsheet.getJson(),
          quote_type: rfq_object.quote_type,
        },
      );

      response.data.id = sessionStorage.getItem("rfqId");
      response.data.quote_type = rfq_object.quote_type;
      if (response.data.quote_type === "DOUBLE_LEG_VOL") {
        response.data.bbg_vol = response.data.bbg_vols;
      }
      rfq_object = { ...rfq_object, quote_parameters: response.data };
      if (
        (rfq_object.deal_stats === null && dealStatsData.makor_bid === "---") ||
        rfq_object?.deal_stats?.client_price_string === "---"
      ) {
        api_initialize_deal_stats();
      }
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };
  const UpdateDealStatsData = async (dealstateData) => {
    const token = sessionStorage.getItem("token");
    dealstateData.rfq_id = sessionStorage.getItem("rfqId");
    try {
      let response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}` + END_POINT.DEAL_STATS,
        { dealstateData },
        { headers: { Authorization: token } },
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    jspreadsheet.hidden_keys = hidden_keys;

    if (!jRef.current.jspreadsheet && jspreadsheet !== null) {
      jspreadsheet(jRef.current, options);
    }

    format_table();
    handle_visible_rows();
    regionFormat.add_dropdown_class_to_deal_type(
      keyVal_input_col,
      getCell,
      keyRow,
    );
  }, [
    row,
    col,
    options,
    second_last_column,
    modalCrateStatus,
    rfqMode,
    rfqObjectUpdate,
    isUserChangeBarrier,
  ]);

  useEffect(() => {
    unhide_all_keys();
    set_hidden_keys();
    jspreadsheet.hidden_keys = hidden_keys;
  }, [hidden_keys, jtable_keys]);

  useEffect(() => {
    const activeRfqArray = activeRfqInstanceList.findIndex(
      (rfq) => rfq.rfq_id === undefined,
    );
    if (activeRfqArray !== -1) {
      reset();
    }
    HandleRfqBlotterChecked();
    if (rfqs.length === 0) {
      dispatch(rfqPostTradeAction.setAllValuesIfConfosExist());
      let search = "";
      let date = "";
      dispatch(rfqSlice.getRfq(search, date));
    }
  }, [rfq, rfqMode, rfqBookingModeUpdatingRows, tableData, status]);

  useEffect(() => {
    let status = sessionStorage.getItem("status");
    if (status === "copy") {
      setLaunchStatus(false);
      dispatch(rfqSlice.clearCreatedRfqDetails());
      // handleCopyRows();
    }
  }, []);
  useEffect(() => {
    const getRfqTrade = (message) => {
      switch (message.data.type) {
        case "updated_rfq":
          break;
        case "update_rfq_status":
          let rfqStatus = message.data.rfqTrade;
          if (rfqStatus !== undefined) {
            dispatch(rfqSlice.updateRfqStatus(rfqStatus));
          }
          break;
        default:
          break;
      }
    };
    window.addEventListener("message", getRfqTrade);

    return () => {
      window.removeEventListener("message", getRfqTrade);
    };
  }, []);
  useEffect(() => {
    let rfqId = sessionStorage.getItem("rfqId");
    if (rfqId !== null && sessionStorage.getItem("status") === "open") {
      dispatch(rfqSlice.getRfqById(rfqId));
    }
  }, [rfqMode]);
  useEffect(() => {
    if (isRfqStatusUpdateLive === true) {
      document.addEventListener("click", (e) => {
        handleStatusUpdate(e);
      });
    }
  }, []);
  useEffect(() => {
    handleReadOnlyClass(
      jRef.current.jspreadsheet.headers,
      jRef.current.jspreadsheet.rows,
      rfqs,
    );
  }, [rfqs, rfqDealType]);

  return (
    <Fragment>
      <Grid container style={{ height: "calc(100vh - 283px)" }}>
        {rfqMode !== "rfq booking view" ? (
          <Grid item lg={8} xs={12}>
            <Box
              display="flex"
              alignItems="center"
              ml={1}
              style={{ marginTop: "10px" }}
            >
              <Button
                onClick={buildRfq}
                style={{
                  background:
                    "var(--unnamed-color-2692ff) 0% 0% no-repeat padding-box",
                  background: launchStatus
                    ? "#2692FF 0% 0% no-repeat padding-box"
                    : "#FF681C 0% 0% no-repeat padding-box",
                  borderRadius: "4px",
                  opacity: 1,
                  width: "124px",
                  height: "40px",
                  marginRight: "18px",
                }}
              >
                <Launch style={{ marginRight: "8px" }} />
                <Typography className={classes.launch}>
                  {launchStatus ? "LAUNCHED" : "LAUNCH"}
                </Typography>
              </Button>
              <NewColumn className={classes.icon} onClick={add_custom_leg} />
              <DeleteColumn className={classes.icon} onClick={delete_leg} />
              <Comment className={classes.icon} onClick={openModal} />
              <Box marginLeft={"auto"} display={"flex"}>
                <IconButton
                  onClick={reset}
                  size="medium"
                  style={{ backgroundColor: "#3A3C3F" }}
                >
                  <Trash
                    style={{
                      background: "#3A3C3F 0% 0% no-repeat padding-box",
                      padding: "0px 2px",
                    }}
                  />
                </IconButton>
              </Box>
            </Box>
          </Grid>
        ) : (
          <Grid></Grid>
        )}
        <Grid
          item
          lg={8}
          md={1}
          xs={12}
          style={{ marginTop: "10px", height: "calc(100vh - 261px)" }}
        >
          <div className="jTable" ref={jRef} />
        </Grid>
        <Grid
          item
          lg={4}
          md={12}
          xs={12}
          style={{
            top: rfqMode === "rfq booking view" ? "-40px" : "-90px",
            position: "relative",
          }}
        >
          <RfqInfoModal
            direction="column"
            rfq_object={rfq_object}
            setrfq_object={setrfq_object}
            newCreatedRfq={newCreatedRfq}
            setLaunchStatus={setLaunchStatus}
            launchStatus={launchStatus}
          />
        </Grid>
      </Grid>
      <ModalCreate
        closeModal={handleOnClickClose}
        rfqId={rfq.length > 0 ? rfq[0].rfq_id : 0}
        rfq={rfq}
      />
    </Fragment>
  );
};

export const useStyles = makeStyles(() => ({
  buttons: {
    marginLeft: "20%",
    width: "524px",
    height: "40px",
  },
  rfqHeader: {
    textAlign: "left",
    font: "normal normal normal 32px/39px Inter",
    letterSpacing: "0px",
    color: "#ffffff",
    opacity: 1,
  },
  rfqtabs: {
    width: "1715px",
    borderBottom: "1px solid #686B76",
  },
  rfqtab: {
    display: "flex",
    flexDirection: "row",
  },
  rfqDescription: {
    textAlign: "center",
    font: "normal normal normal 16px/20px Inter",
    letterSpacing: "0px",
    color: "#FFFFFF",
    fontSize: "1.3125rem",
    marginRight: "10px",
    background: "#2D2D2D 0% 0% no-repeat padding-box",
    borderradius: "4px 4px 0px 0px",
    opacity: 1,
    width: "267px",
    height: "40px",
    display: "flex",
    justifycontent: "space-between",
  },
  rfqTabsDescription: {
    marginLeft: "15px",
    marginTop: "10px",
  },
  rfqTabsCloseIcon: {
    marginTop: "10px",
    marginRight: "10px",
  },
  rfqTabsAddIcon: {
    marginTop: "10px",
    marginRight: "10px",
  },
  bottomContainer: {
    background: "#222222 0% 0% no-repeat padding-box",
    borderRadius: "4px",
    marginTop: "8px",
  },
  icon: {
    marginRight: "20px",
  },
  trash: {
    marginRight: "20px",
    marginTop: "11px",
    marginLeft: "13px",
    background: "#3A3C3F 0% 0% no-repeat padding-box",
  },
  buttonGroup: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // width: "1715px",
    height: "57px",
  },
  launch: {
    textAlign: "left",
    fontSize: "1.125rem",
    font: "normal normal normal 16px/20px Inter",
    letterSpacing: "0px",
    color: "#F2F2F2",
    opacity: 1,
  },
}));
