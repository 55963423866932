import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  Grid,
  IconButton,
  TableContainer,
  Typography,
} from "@material-ui/core";
import NearFarTable from "./PreviewTable";
import CloseIcon from "@material-ui/icons/Close";
import { useStyles } from "./style";
import { useDispatch, useSelector } from "react-redux";
import { submitEConfo } from "./apiCalls";

export const RfqEConfoModal = ({ open, handleClose, rfqObject }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isSubmitValid, setIsSubmitValid] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [legsStatus, setLegStatus] = useState(null);
  const [tableData, setTableData] = useState(null);

  const { currentClient, currentMarketMaker, currentPb, trader } = useSelector(
    (state) => state.rfqPostTradeSlice,
  );
  const { dealStatsData } = useSelector((state) => state.rfqSlice);
  const handleCancel = () => {
    handleClose();
  };
  const handleSubmit = async () => {
    await submitEConfo(
      rfqObject,
      currentClient,
      currentMarketMaker,
      currentPb,
      trader,
      dispatch,
      setIsSubmitting,
      setIsSubmitValid,
      setLegStatus,
      tableData,
      dealStatsData,
    );
  };

  useEffect(() => {
    if (!open) {
      // Reset states when modal closes
      setIsSubmitValid(false);
      setIsSubmitting(false);
      setLegStatus(null);
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      classes={{ paper: classes.dialogPaperBookTrade }}
    >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        {/* close icon */}
        <Grid
          item
          xs={12}
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          style={{ marginBottom: 20 }}
        >
          <IconButton
            size="small"
            onClick={() => {
              handleClose();
            }}
            style={{
              backgroundColor: "#3a3c3f",
            }}
          >
            <CloseIcon style={{}} />
          </IconButton>
        </Grid>
        <Grid item xs={12} style={{ marginBottom: 50 }}>
          <Typography
            style={{
              fontSize: "2.5rem",
              color: "#ffffff",
            }}
          >
            E-Confo
          </Typography>
        </Grid>
        <TableContainer
          style={{
            height: "100%", // Ensure the table container takes available height
            maxHeight: "100%", // Prevent overflow by restricting max height
            overflow: "auto", // Enable scrolling if the content exceeds height
          }}
        >
          {open ? (
            <NearFarTable
              rfqObject={rfqObject}
              setIsSubmitValid={setIsSubmitValid}
              legsStatus={legsStatus}
              tableData={tableData}
              setTableData={setTableData}
            />
          ) : null}
        </TableContainer>
        <Grid
          container
          direction="row"
          justifyContent="space-around"
          alignItems="center"
          style={{ marginTop: "7vh" }}
        >
          <Button
            variant="contained"
            className={classes.confoBtn}
            onClick={() => {
              handleClose();
            }}
            // style={{ width: "150px", height: 30, backgroundColor: "#2692ff" }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            className={classes.confoBtn}
            onClick={() => {
              handleSubmit();
            }}
            disabled={!isSubmitValid}
            style={{ position: "relative" }}
          >
            {isSubmitting ? (
              <CircularProgress
                size={24} // Adjust size as needed
                style={{
                  color: "white",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: -12, // Half of the size to center vertically
                  marginLeft: -12, // Half of the size to center horizontally
                }}
              />
            ) : !isSubmitValid ? (
              "Sent"
            ) : (
              "Submit"
            )}
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
};
